.modal-open {
    overflow: hidden;

    .modal {
        overflow-x: hidden;
        overflow-y: auto;
        pointer-events: all !important;
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10003;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;

    & .close {
        background-color: transparent;
        border: 1px solid transparent;
        padding: 0;
        width: 50px;
        height: 50px;
        margin: -.5rem -.5rem -1rem auto;
        color: $cl-cta;
        @include font-size(24px);
    }
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem auto;
    pointer-events: none;

    &--success {
        .modal-header {
            background: $cl-green;
            color: $cl-white;

            .modal-title {
                color: $cl-white;
            }

            & .close {
                color: $cl-white;
            }
        }
    }

    .modal.fade & {
        transition: transform .3s ease-out;
        transform: translate(0, -50px);
    }
    .modal.show & {
        transform: none;
    }

    .modal.modal-static & {
        transform: scale(1.02);
    }
}

.modal-dialog-scrollable {
    display: flex; // IE10/11
    max-height: subtract(100%, .5rem* 2);

    .modal-content {
        max-height: subtract(100vh, .5rem * 2); // IE10/11
        overflow: hidden;
    }

    .modal-header,
    .modal-footer {
        flex-shrink: 0;
    }

    .modal-body {
        overflow-y: auto;
    }
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: subtract(100%, .5rem * 2);

    &::before {
        display: block;
        height: subtract(100vh, .5rem * 2);
        height: min-content;
        content: "";
    }

    &.modal-dialog-scrollable {
        flex-direction: column;
        justify-content: center;
        height: 100%;

        .modal-content {
            max-height: none;
        }

        &::before {
            content: none;
        }
    }
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: $cl-white;
    background-clip: padding-box;
    border-radius: $radius;

    outline: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: rgba(189,172,142 , .7);

    &.fade { opacity: 0; }
    &.show { opacity: .5; }
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1 solid rgba($cl-black, .2);
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;

    .close {
        padding: 1rem;
        margin: (-1rem) (-1rem) (-1rem) auto;
    }
}

.modal-title {
    margin: 0;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center; // vertically center
    justify-content: flex-end; // Right align buttons with flex property because text-align doesn't work on flex items
    padding: 1rem - .5rem / 2;
    border-top: 1px solid rgba($cl-black, .2);

    > * {
        margin: .5rem / 2;
    }
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

@include query($grid-s) {
    // Automatically set modal's width for larger viewports
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }

    .modal-dialog-scrollable {
        max-height: subtract(100%,  1.75rem * 2);

        .modal-content {
            max-height: subtract(100vh,  1.75rem * 2);
        }
    }

    .modal-dialog-centered {
        min-height: subtract(100%,  1.75rem * 2);

        &::before {
            height: subtract(100vh,  1.75rem * 2);
            height: min-content;
        }
    }
}

@include query-min($grid-l) {
    .modal-lg,
    .modal-xl {
        max-width: 800px;
    }
}

@include query-min($grid-xl) {
    .modal-xl { max-width: 1140px; }
}
