@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Open Sans'),
  url('../../../public/frontend/fonts/open-sans-v29-latin-ext_latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../../../public/frontend/fonts/open-sans-v29-latin-ext_latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
  url('../../../public/frontend/fonts/open-sans-v29-latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../../../public/frontend/fonts/open-sans-v29-latin-ext_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Open Sans'),
  url('../../../public/frontend/fonts/open-sans-v29-latin-ext_latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('../../../public/frontend/fonts/open-sans-v29-latin-ext_latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Open Sans-fallback";
  size-adjust: 105.44%;
  ascent-override: 103%;
  src: local("Arial");
}

// Proxima bold
@mixin bold() {font-weight: 700;}

// Proxima semi
@mixin semi() {font-weight: 500;}

// Proxima regular
@mixin regular() { font-weight: 300;}

// Proxima light
@mixin light() { font-weight: 100;}
