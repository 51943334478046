.our-projects {

    margin-top: 40px;
    padding: 40px 0;
    background-color: $cl-light;

    .row {
        display: flex;
        margin: 0;

        @include query($grid-l) {
            display: block;
        }
    }
    
    &--heading {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;


        @include font-size(28px);
        margin: 0;
        @include light();
        line-height: 1;

        span {
            @include font-size(36px);
             font-weight: 500;
        }

        @include query($grid-l) {
            padding-bottom: 40px !important;
        }
    }

    &--item {
        padding: 0 20px;
        box-sizing: border-box;

        h4 {
            @include font-size(24px);
             font-weight: 500;
            margin: 0;
        }

        a {
            text-decoration: underline;
            color: $cl-dark;
             font-weight: 700;

            &:hover {
                color: $cl-cta;
            }
        }

        @include query($grid-m) {
            padding: 30px 20px !important;
        }
    }
}