/*-----------------------------------------------------------------------------------*/
/*  HELPERS
/*-----------------------------------------------------------------------------------*/

//CUSTOM
.hover {
  @include animate(250);

  img {
    @include imghf();
  }

  &:hover {
    @include opacity(0.8);
  }
}

.flex-center {
  display: flex;
  justify-content: center;
}

.table {
  display: table;
  width: 100%;
  vertical-align: middle;
}

.cell {
  display: table-cell;
  vertical-align: middle;
}

.wow {
  visibility: hidden;

  &.animated {
    visibility: visible;
  }

  @include oldie {
    visibility: visible !important;
  }
}


// TEXT
.text-right {
  text-align: right
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

@include query($grid-m) {
  .text-m-center {
    text-align: center !important;
  }
}

.text-justify {
  text-align: justify
}

.uppercase {
  text-transform: uppercase;
}

.text-underline {
  text-decoration: underline
}

.text-no-decoration {
  text-decoration: none
}

.text-strike {
  text-decoration: line-through
}

.text-no-wrap {
  white-space: nowrap
}

.text-wrap {
  word-wrap: break-word
}

// MARGIN
.m0 {
  margin: 0px
}

.m5 {
  margin: 5px !important;
}

.m10 {
  margin: 10px !important;
}

.m15 {
  margin: 15px !important;
}

.m20 {
  margin: 20px !important;
}

.m25 {
  margin: 25px !important;
}

.m30 {
  margin: 30px !important;
}

.m35 {
  margin: 35px !important;
}

.m40 {
  margin: 40px !important;
}

.m45 {
  margin: 45px !important;
}

.m50 {
  margin: 50px !important;
}

.m55 {
  margin: 55px !important;
}

.m60 {
  margin: 60px !important;
}

.ml0 {
  margin-left: 0px !important;
}

.ml5 {
  margin-left: 5px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.ml25 {
  margin-left: 25px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.ml35 {
  margin-left: 35px !important;
}

.ml40 {
  margin-left: 40px !important;
}

.ml45 {
  margin-left: 45px !important;
}

.ml50 {
  margin-left: 50px !important;
}

.ml55 {
  margin-left: 55px !important;
}

.ml60 {
  margin-left: 60px !important;
}

.mr0 {
  margin-right: 0px !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mr25 {
  margin-right: 25px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.mr35 {
  margin-right: 35px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.mr45 {
  margin-right: 45px !important;
}

.mr50 {
  margin-right: 50px !important;
}

.mr55 {
  margin-right: 55px !important;
}

.mr60 {
  margin-right: 60px !important;
}

.mt0 {
  margin-top: 0px !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt35 {
  margin-top: 35px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt45 {
  margin-top: 45px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt55 {
  margin-top: 55px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.mb0 {
  margin-bottom: 0px !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb35 {
  margin-bottom: 35px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb45 {
  margin-bottom: 45px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb55 {
  margin-bottom: 55px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

// PADDING
.p0 {
  padding: 0px !important;
}

.p5 {
  padding: 5px !important;
}

.p10 {
  padding: 10px !important;
}

.p15 {
  padding: 15px !important;
}

.p20 {
  padding: 20px !important;
}

.p25 {
  padding: 25px !important;
}

.p30 {
  padding: 30px !important;
}

.p35 {
  padding: 35px !important;
}

.p40 {
  padding: 40px !important;
}

.p45 {
  padding: 45px !important;
}

.p50 {
  padding: 50px !important;
}

.p55 {
  padding: 55px !important;
}

.p60 {
  padding: 60px !important;
}

.pl0 {
  padding-left: 0px !important;
}

.pl5 {
  padding-left: 5px !important;
}

.pl10 {
  padding-left: 10px !important;
}

.pl15 {
  padding-left: 15px !important;
}

.pl20 {
  padding-left: 20px !important;
}

.pl25 {
  padding-left: 25px !important;
}

.pl30 {
  padding-left: 30px !important;
}

.pl35 {
  padding-left: 35px !important;
}

.pl40 {
  padding-left: 40px !important;
}

.pl45 {
  padding-left: 45px !important;
}

.pl50 {
  padding-left: 50px !important;
}

.pl55 {
  padding-left: 55px !important;
}

.pl60 {
  padding-left: 60px !important;
}

.pr0 {
  padding-right: 0px !important;
}

.pr5 {
  padding-right: 5px !important;
}

.pr10 {
  padding-right: 10px !important;
}

.pr15 {
  padding-right: 15px !important;
}

.pr20 {
  padding-right: 20px !important;
}

.pr25 {
  padding-right: 25px !important;
}

.pr30 {
  padding-right: 30px !important;
}

.pr35 {
  padding-right: 35px !important;
}

.pr40 {
  padding-right: 40px !important;
}

.pr45 {
  padding-right: 45px !important;
}

.pr50 {
  padding-right: 50px !important;
}

.pr55 {
  padding-right: 55px !important;
}

.pr60 {
  padding-right: 60px !important;
}

.pt0 {
  padding-top: 0px !important;
}

.pt5 {
  padding-top: 5px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.pt15 {
  padding-top: 15px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.pt25 {
  padding-top: 25px !important;
}

.pt30 {
  padding-top: 30px !important;
}

.pt35 {
  padding-top: 35px !important;
}

.pt40 {
  padding-top: 40px !important;
}

.pt45 {
  padding-top: 45px !important;
}

.pt50 {
  padding-top: 50px !important;
}

.pt55 {
  padding-top: 55px !important;
}

.pt60 {
  padding-top: 60px !important;
}

.pb0 {
  padding-bottom: 0px !important;
}

.pb5 {
  padding-bottom: 5px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.pb15 {
  padding-bottom: 15px !important;
}

.pb20 {
  padding-bottom: 20px !important;
}

.pb25 {
  padding-bottom: 25px !important;
}

.pb30 {
  padding-bottom: 30px !important;
}

.pb35 {
  padding-bottom: 35px !important;
}

.pb40 {
  padding-bottom: 40px !important;
}

.pb45 {
  padding-bottom: 45px !important;
}

.pb50 {
  padding-bottom: 50px !important;
}

.pb55 {
  padding-bottom: 55px !important;
}

.pb60 {
  padding-bottom: 60px !important;
}

// FONT
.fs10 {
  font-size: 10px
}

.fs11 {
  font-size: 11px
}

.fs12 {
  font-size: 12px
}

.fs13 {
  font-size: 13px
}

.fs14 {
  font-size: 14px
}

.fs15 {
  font-size: 15px
}

.fs16 {
  font-size: 16px
}

.fs17 {
  font-size: 17px
}

.fs18 {
  font-size: 18px
}

.fs19 {
  font-size: 19px
}

.fs20 {
  font-size: 20px
}

.fs21 {
  font-size: 21px
}

.fs22 {
  font-size: 22px
}

.fs23 {
  font-size: 23px
}

.fs24 {
  font-size: 24px
}

.fw-normal {
  font-weight: normal
}

.fw-bold {
  font-weight: bold
}

.fstyle-normal {
  font-style: normal
}

.fstyle-italic {
  font-style: italic
}

// FLOAT + CLEAR
.clear {
  clear: both
}

.clear-r {
  clear: right
}

.clear-l {
  clear: left
}

.flr {
  float: right;
}

.fll {
  float: left;
}

.fnone {
  float: none
}

// DISPLAY
.display-inline {
  display: inline
}

.display-inline-block {
  display: inline-block
}

.display-block {
  display: block
}

.display-none {
  display: none
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
  float: none;
}

// CURSOR
.c-pointer {
  cursor: pointer
}

.c-move {
  cursor: move
}

.c-auto {
  cursor: auto
}

.c-help {
  cursor: help
}

// OTHER
.relative {
  position: relative
}

