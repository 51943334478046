$custom-checkbox-width: 25px;
$custom-checkbox-height: 25px;
$custom-checkbox-check-width: 15px;
$custom-checkbox-check-height: 15px;

.custom-checkbox {
  display: grid;
  grid-template-columns: 25px auto;
  gap: 0.5em;
  line-height: $custom-checkbox-height;

  & input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;

    margin: 0;
    padding: 0;

    font: inherit;
    color: currentColor;
    width: $custom-checkbox-width;
    height: $custom-checkbox-height;
    border: 1px solid $cl-cta;
    border-radius: $radius;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;

    &::before {
      content: "";
      width: $custom-checkbox-check-width;
      height: $custom-checkbox-check-height;
      background: $cl-cta;
      transform: scale(0);
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      transition: $transition;
    }

    &:checked::before {
      transform: scale(1);
    }
  }
}
