$icons: (
    sprite: (
      width: 204px, 
      height: 145px, 
      path: "../../../../public/frontend/images/pngSprite.png"
    ),
    b-l: (
      width: 22px, 
      height: 9px, 
      backgroundX: -34px, 
      backgroundY: -126px
    ),
    b-r: (
      width: 22px, 
      height: 9px, 
      backgroundX: -66px, 
      backgroundY: -126px
    ),
    basket: (
      width: 35px, 
      height: 35px, 
      backgroundX: -138px, 
      backgroundY: -45px
    ),
    box-topSix: (
      width: 20px, 
      height: 20px, 
      backgroundX: -184px, 
      backgroundY: 0px
    ),
    bread: (
      width: 6px, 
      height: 6px, 
      backgroundX: -184px, 
      backgroundY: -132px
    ),
    delete: (
      width: 11px, 
      height: 11px, 
      backgroundX: -184px, 
      backgroundY: -79px
    ),
    ko: (
      width: 20px, 
      height: 20px, 
      backgroundX: -184px, 
      backgroundY: -30px
    ),
    nav-arrow: (
      width: 10px, 
      height: 6px, 
      backgroundX: -184px, 
      backgroundY: -100px
    ),
    nav: (
      width: 30px, 
      height: 24px, 
      backgroundX: -138px, 
      backgroundY: -90px
    ),
    navTop-arrow: (
      width: 9px, 
      height: 6px, 
      backgroundX: -184px, 
      backgroundY: -116px
    ),
    next: (
      width: 59px, 
      height: 116px, 
      backgroundX: -69px, 
      backgroundY: 0px
    ),
    ok: (
      width: 24px, 
      height: 19px, 
      backgroundX: 0px, 
      backgroundY: -126px
    ),
    phone: (
      width: 36px, 
      height: 35px, 
      backgroundX: -138px, 
      backgroundY: 0px
    ),
    prev: (
      width: 59px, 
      height: 116px, 
      backgroundX: 0px, 
      backgroundY: 0px
    ),
    sub-arrwo: (
      width: 17px, 
      height: 9px, 
      backgroundX: -184px, 
      backgroundY: -60px
    ),
);



$sprite: map-get($icons, sprite) !default;

@function sprite-attr($icon, $attr) {
  $newIcon: map-get($icons, $icon);
  @if $newIcon == null {
    @warn "Can't find an icon with the name #{$icon}";
  }
  @return map-get($newIcon, $attr);
}


@function icon-attr($icon) {
  $attr: (
    width: sprite-attr($icon, width),
    height: sprite-attr($icon, height),
    x: sprite-attr($icon, backgroundX),
    y: sprite-attr($icon, backgroundY)
  );

  @return $attr;
}


%sprite {
  background-image: url(map-get($sprite, path));
}

@mixin ha($width, $height, $type, $unit) {
  @if $type != none {
    position: absolute;
    left: 50%;
  }
  margin-left: $width/(-2);
  margin-top: $height/(-2);
}

@mixin va($width, $height, $type, $unit) {
  @if $type != none {
    position: absolute;
    top: 50%;
  }
  margin-top: $height/(-2);
}

@mixin ba($width, $height, $type, $unit) {
  @if $type != none {
    position: absolute;
    left: 50%;top: 50%;
  }
  margin-left: $width/(-2);
  margin-top: $height/(-2);
}

@mixin sprite($icon, $align:false, $type: all) {

  @if $type == all {
    @extend %sprite;
  }

  $iconMap: icon-attr($icon);

  @if $type == all or $type == size or $type == iconClass {
    width: map-get($iconMap, width);
    height: map-get($iconMap, height);
  }

  @if $type == all or $type == bg or $type == iconClass {
    background-position: map-get($iconMap, x) map-get($iconMap, y);
  }

  @if $align == "ha" {
    @include ha(map-get($iconMap, width), map-get($iconMap, height), $type, rem);
  }
  @if $align == "va" {
    @include va(map-get($iconMap, width), map-get($iconMap, height), $type, rem);
  }
  @if $align == "ba" {
    @include ba(map-get($iconMap, width), map-get($iconMap, height), $type, rem);
  }

}

@each $spriteImage in map-keys($icons){

  @if $spriteImage != "sprite" {

    $iconMap: icon-attr($spriteImage);

    .icon.#{$spriteImage},.icon-a.#{$spriteImage}:after,.icon-b.#{$spriteImage}:before {
      @include sprite($spriteImage, false, iconClass);
      }
      .ha{
        &.icon.#{$spriteImage},&.icon-a.#{$spriteImage}:after,&.icon-b.#{$spriteImage}:before{
          @include sprite($spriteImage, ha, none);
        }
      }
      .va{
        &.icon.#{$spriteImage},&.icon-a.#{$spriteImage}:after,&.icon-b.#{$spriteImage}:before{
          @include sprite($spriteImage, va, none);
        }
      }
      .ba{
        &.icon.#{$spriteImage},&.icon-a.#{$spriteImage}:after,&.icon-b.#{$spriteImage}:before{
          @include sprite($spriteImage, ba, none);
        }
      }
  }
}

// ICONS default
.icon-b:before{display: inline-block;content: "";vertical-align: middle;margin-right: 10px;@extend %sprite;}
.icon-a:after{display: inline-block;content: "";vertical-align: middle;margin-left: 10px;@extend %sprite;}

.icon-a,.icon-b{
  &.va,&.ba,&.ha{position: relative;}
}


.icon{display: block;@extend %sprite;}


.ha{
  &.icon-b:before,&.icon,&.icon-a:after{
    position: absolute;left: 50%;top: 0;
  }
}
.ba{
  &.icon-b:before,&.icon,&.icon-a:after{
    position: absolute;top: 50%;left: 50%;
  }
}
.va{
  &.icon-b:before,&.icon,&.icon-a:after{
    position: absolute;left:0;top: 50%;
  }
}

