[class*=grid] {
  float: left;
  width: 100%;

  &.flr {
    float: right;
  }

  &.center {
    clear: both;
    float: none !important;
    margin: 0 auto;
  }
}

.grid-12 {
  width: 100%;
}

.grid-11 {
  width: 91.66%;
}

.grid-105 {
  width: 87.5%;
}

.grid-10 {
  width: 83.33%;
}

.grid-94 {
  width: 80%;
}

.grid-9 {
  width: 75%;
}

.grid-8 {
  width: 66.66%;
}

.grid-7 {
  width: 58.33%;
}

.grid-6 {
  width: 50%;
}

.grid-58 {
  width: 48.33%;
}

.grid-5 {
  width: 41.66%;
}

.grid-48 {
  width: 40%;
}

.grid-4 {
  width: 33.33%;
}

.grid-3 {
  width: 25%;
}

.grid-24 {
  width: 20%;
}

.grid-2 {
  width: 16.66%;
}

.grid-15 {
  width: 12.5%;
}

.grid-1 {
  width: 8.33%;
}

@include query(1299) {
  .grid-xxl-12 {
    width: 100%;
  }

  .grid-xxl-11 {
    width: 91.66%;
  }

  .grid-xxl-105 {
    width: 87.5%;
  }

  .grid-xxl-10 {
    width: 83.33%;
  }

  .grid-xxl-94 {
    width: 80%;
  }

  .grid-xxl-9 {
    width: 75%;
  }

  .grid-xxl-8 {
    width: 66.66%;
  }

  .grid-xxl-7 {
    width: 58.33%;
  }

  .grid-xxl-6 {
    width: 50%;
  }

  .grid-xxl-58 {
    width: 48.33%;
  }

  .grid-xxl-5 {
    width: 41.66%;
  }

  .grid-xxl-48 {
    width: 40%;
  }

  .grid-xxl-4 {
    width: 33.33%;
  }

  .grid-xxl-3 {
    width: 25%;
  }

  .grid-xxl-24 {
    width: 20%;
  }

  .grid-xxl-2 {
    width: 16.66%;
  }

  .grid-xxl-15 {
    width: 12.5%;
  }

  .grid-xxl-1 {
    width: 8.33%;
  }
}

@include query($grid-xl) {
  .grid-xl-12 {
    width: 100%;
  }

  .grid-xl-11 {
    width: 91.66%;
  }

  .grid-xl-105 {
    width: 87.5%;
  }

  .grid-xl-10 {
    width: 83.33%;
  }

  .grid-xl-94 {
    width: 80%;
  }

  .grid-xl-9 {
    width: 75%;
  }

  .grid-xl-8 {
    width: 66.66%;
  }

  .grid-xl-7 {
    width: 58.33%;
  }

  .grid-xl-6 {
    width: 50%;
  }

  .grid-xl-58 {
    width: 48.33%;
  }

  .grid-xl-5 {
    width: 41.66%;
  }

  .grid-xl-48 {
    width: 40%;
  }

  .grid-xl-4 {
    width: 33.33%;
  }

  .grid-xl-3 {
    width: 25%;
  }

  .grid-xl-24 {
    width: 20%;
  }

  .grid-xl-2 {
    width: 16.66%;
  }

  .grid-xl-15 {
    width: 12.5%;
  }

  .grid-xl-1 {
    width: 8.33%;
  }

}

@include query($grid-l) {
  .grid-l-12 {
    width: 100%;
  }

  .grid-l-11 {
    width: 91.66%;
  }

  .grid-l-105 {
    width: 87.5%;
  }

  .grid-l-10 {
    width: 83.33%;
  }

  .grid-l-94 {
    width: 80%;
  }

  .grid-l-9 {
    width: 75%;
  }

  .grid-l-8 {
    width: 66.66%;
  }

  .grid-l-7 {
    width: 58.33%;
  }

  .grid-l-6 {
    width: 50%;
  }

  .grid-l-58 {
    width: 48.33%;
  }

  .grid-l-5 {
    width: 41.66%;
  }

  .grid-l-48 {
    width: 40%;
  }

  .grid-l-4 {
    width: 33.33%;
  }

  .grid-l-3 {
    width: 25%;
  }

  .grid-l-24 {
    width: 20%;
  }

  .grid-l-2 {
    width: 16.66%;
  }

  .grid-l-15 {
    width: 12.5%;
  }

  .grid-l-1 {
    width: 8.33%;
  }
}

@include query($grid-m) {
  .grid-m-12 {
    width: 100%;
  }

  .grid-m-11 {
    width: 91.66%;
  }

  .grid-m-105 {
    width: 87.5%;
  }

  .grid-m-10 {
    width: 83.33%;
  }

  .grid-m-94 {
    width: 80%;
  }

  .grid-m-9 {
    width: 75%;
  }

  .grid-m-8 {
    width: 66.66%;
  }

  .grid-m-7 {
    width: 58.33%;
  }

  .grid-m-6 {
    width: 50%;
  }

  .grid-m-58 {
    width: 48.33%;
  }

  .grid-m-5 {
    width: 41.66%;
  }

  .grid-m-48 {
    width: 40%;
  }

  .grid-m-4 {
    width: 33.33%;
  }

  .grid-m-3 {
    width: 25%;
  }

  .grid-m-24 {
    width: 20%;
  }

  .grid-m-2 {
    width: 16.66%;
  }

  .grid-m-15 {
    width: 12.5%;
  }

  .grid-m-1 {
    width: 8.33%;
  }
}

@include query($grid-s) {
  .grid-s-12 {
    width: 100%;
  }

  .grid-s-11 {
    width: 91.66%;
  }

  .grid-s-105 {
    width: 87.5%;
  }

  .grid-s-10 {
    width: 83.33%;
  }

  .grid-s-94 {
    width: 80%;
  }

  .grid-s-9 {
    width: 75%;
  }

  .grid-s-8 {
    width: 66.66%;
  }

  .grid-s-7 {
    width: 58.33%;
  }

  .grid-s-6 {
    width: 50%;
  }

  .grid-s-58 {
    width: 48.33%;
  }

  .grid-s-5 {
    width: 41.66%;
  }

  .grid-s-48 {
    width: 40%;
  }

  .grid-s-4 {
    width: 33.33%;
  }

  .grid-s-3 {
    width: 25%;
  }

  .grid-s-24 {
    width: 20%;
  }

  .grid-s-2 {
    width: 16.66%;
  }

  .grid-s-15 {
    width: 12.5%;
  }

  .grid-s-1 {
    width: 8.33%;
  }
}
