.spinbox {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding: 3px 25px 3px 3px;
    width: 78px;
    height: 38px;
    background: $cl-white;
    border: 1px solid #ccc;
    border-radius: $radius;

    &__input {
        width: 26px;
        height: 36px;
        line-height: 35px;
        padding: 0;
        margin-top: -3px;
        text-align: center;
        position: absolute;
        left: 25px;
        background: #fff;
        font-size: 16px;
        color: #868686;
        border-radius: 0;
        border: 0;
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    &__button {
        position: absolute;
        top: 0;
        left: 51px;
        width: 25px;
        height: 100%;
        line-height: 32px;
        color: #8f8f8f;
        cursor: pointer;
        border-radius: 0 $radius $radius 0;
        font-size: 20px;
        background: #e2e2e2;
        border: 0;
        border-left: 1px solid #ccc;

        &--minus {
            top: auto;
            bottom: 0;
            left: 0;
            border-radius: 3px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-left: 0;
            border-right: 1px solid #d0d0d0;
        }
    }
}