/*-----------------------------------------------------------------------------------*/
/*  FORMS BASIC SETTINGS
/*-----------------------------------------------------------------------------------*/

input, select, textarea {
	vertical-align:middle;

	&:focus {
		outline: none;
	}
}