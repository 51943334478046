/*************************************************************************************
*  HELPERS
**************************************************************************************/
@include query($grid-s) {

	.text-right-mob {text-align: right}
	.text-left-mob {text-align: left}
	.text-center-mob {text-align: center}
	.text-justify-mob {text-align: justify}

	.text-underline-mob {text-decoration: underline}
	.text-no-decoration-mob {text-decoration: none}
	.text-strike-mob {text-decoration: line-through}

	.text-no-wrap-mob {white-space: nowrap}
	.text-wrap-mob {word-wrap: break-word}

	// MARGIN
	.m0-mob {margin: 0px}
	.m5-mob {margin: 5px}
	.m10-mob {margin: 10px}
	.m15-mob {margin: 15px}
	.m20-mob {margin: 20px}
	.m25-mob {margin: 25px}
	.m30-mob {margin: 30px}
	.m35-mob {margin: 35px}
	.m40-mob {margin: 40px}
	.m45-mob {margin: 45px}
	.m50-mob {margin: 50px}
	.m55-mob {margin: 55px}
	.m60-mob {margin: 60px}

	.ml0-mob {margin-left: 0px}
	.ml5-mob {margin-left: 5px}
	.ml10-mob {margin-left: 10px}
	.ml15-mob {margin-left: 15px}
	.ml20-mob {margin-left: 20px}
	.ml25-mob {margin-left: 25px}
	.ml30-mob {margin-left: 30px}
	.ml35-mob {margin-left: 35px}
	.ml40-mob {margin-left: 40px}
	.ml45-mob {margin-left: 45px}
	.ml50-mob {margin-left: 50px}
	.ml55-mob {margin-left: 55px}
	.ml60-mob {margin-left: 60px}

	.mr0-mob {margin-right: 0px}
	.mr5-mob {margin-right: 5px}
	.mr10-mob {margin-right: 10px}
	.mr15-mob {margin-right: 15px}
	.mr20-mob {margin-right: 20px}
	.mr25-mob {margin-right: 25px}
	.mr30-mob {margin-right: 30px}
	.mr35-mob {margin-right: 35px}
	.mr40-mob {margin-right: 40px}
	.mr45-mob {margin-right: 45px}
	.mr50-mob {margin-right: 50px}
	.mr55-mob {margin-right: 55px}
	.mr60-mob {margin-right: 60px}

	.mt0-mob {margin-top: 0px}
	.mt5-mob {margin-top: 5px}
	.mt10-mob {margin-top: 10px}
	.mt15-mob {margin-top: 15px}
	.mt20-mob {margin-top: 20px}
	.mt25-mob {margin-top: 25px}
	.mt30-mob {margin-top: 30px}
	.mt35-mob {margin-top: 35px}
	.mt40-mob {margin-top: 40px}
	.mt45-mob {margin-top: 45px}
	.mt50-mob {margin-top: 50px}
	.mt55-mob {margin-top: 55px}
	.mt60-mob {margin-top: 60px}

	.mb0-mob {margin-bottom: 0px}
	.mb5-mob {margin-bottom: 5px}
	.mb10-mob {margin-bottom: 10px}
	.mb15-mob {margin-bottom: 15px}
	.mb20-mob {margin-bottom: 20px}
	.mb25-mob {margin-bottom: 25px}
	.mb30-mob {margin-bottom: 30px}
	.mb35-mob {margin-bottom: 35px}
	.mb40-mob {margin-bottom: 40px}
	.mb45-mob {margin-bottom: 45px}
	.mb50-mob {margin-bottom: 50px}
	.mb55-mob {margin-bottom: 55px}
	.mb60-mob {margin-bottom: 60px}

	// PADDING
	.p0-mob {padding: 0px}
	.p5-mob {padding: 5px}
	.p10-mob {padding: 10px}
	.p15-mob {padding: 15px}
	.p20-mob {padding: 20px}
	.p25-mob {padding: 25px}
	.p30-mob {padding: 30px}
	.p35-mob {padding: 35px}
	.p40-mob {padding: 40px}
	.p45-mob {padding: 45px}
	.p50-mob {padding: 50px}
	.p55-mob {padding: 55px}
	.p60-mob {padding: 60px}

	.pl0-mob {padding-left: 0px}
	.pl5-mob {padding-left: 5px}
	.pl10-mob {padding-left: 10px}
	.pl15-mob {padding-left: 15px}
	.pl20-mob {padding-left: 20px}
	.pl25-mob {padding-left: 25px}
	.pl30-mob {padding-left: 30px}
	.pl35-mob {padding-left: 35px}
	.pl40-mob {padding-left: 40px}
	.pl45-mob {padding-left: 45px}
	.pl50-mob {padding-left: 50px}
	.pl55-mob {padding-left: 55px}
	.pl60-mob {padding-left: 60px}

	.pr0-mob {padding-right: 0px}
	.pr5-mob {padding-right: 5px}
	.pr10-mob {padding-right: 10px}
	.pr15-mob {padding-right: 15px}
	.pr20-mob {padding-right: 20px}
	.pr25-mob {padding-right: 25px}
	.pr30-mob {padding-right: 30px}
	.pr35-mob {padding-right: 35px}
	.pr40-mob {padding-right: 40px}
	.pr45-mob {padding-right: 45px}
	.pr50-mob {padding-right: 50px}
	.pr55-mob {padding-right: 55px}
	.pr60-mob {padding-right: 60px}

	.pt0-mob {padding-top: 0px}
	.pt5-mob {padding-top: 5px}
	.pt10-mob {padding-top: 10px}
	.pt15-mob {padding-top: 15px}
	.pt20-mob {padding-top: 20px}
	.pt25-mob {padding-top: 25px}
	.pt30-mob {padding-top: 30px}
	.pt35-mob {padding-top: 35px}
	.pt40-mob {padding-top: 40px}
	.pt45-mob {padding-top: 45px}
	.pt50-mob {padding-top: 50px}
	.pt55-mob {padding-top: 55px}
	.pt60-mob {padding-top: 60px}

	.pb0-mob {padding-bottom: 0px}
	.pb5-mob {padding-bottom: 5px}
	.pb10-mob {padding-bottom: 10px}
	.pb15-mob {padding-bottom: 15px}
	.pb20-mob {padding-bottom: 20px}
	.pb25-mob {padding-bottom: 25px}
	.pb30-mob {padding-bottom: 30px}
	.pb35-mob {padding-bottom: 35px}
	.pb40-mob {padding-bottom: 40px}
	.pb45-mob {padding-bottom: 45px}
	.pb50-mob {padding-bottom: 50px}
	.pb55-mob {padding-bottom: 55px}
	.pb60-mob {padding-bottom: 60px}

	// FLOAT + CLEAR
	.clear-mob {clear: both}
	.clear-r-mob {clear: right}
	.clear-l-mob {clear: left}

	.left-mob {float: left}
	.right-mob {float: right}
	.fnone-mob {float: none}

	// DISPLAY
	.display-inline-mob {display: inline !important;}
	.display-inline-block-mob {display: inline-block !important;}
	.display-block-mob {display: block !important;}
	.display-none-mob {display: none !important;}
}