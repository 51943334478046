$cart-preview-color: #000;

.cart-preview {
    display: flex;
    position: relative;
    align-items: center;

    &__block {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 14px;
        text-decoration: none;
        color: $cart-preview-color;
        padding: 10px 0;

        &:hover {
            text-decoration: none;
        }
    }

    &__icon {
        line-height: 1;

        position: relative;
        color: $cart-preview-color;

        @include query-min($grid-m) {
            padding: 7px 14px;
            background: #dfdfdf;
            border-radius: $radius;
        }

        svg {
            width: 19px;
        }

        &__count {
            position: absolute;
            width: 16px;
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            @include font-size(10px);
            background: $cl-cta;
            color: $cl-white;
            font-weight: 700;
            border-radius: 50%;

            top: -8px;
            right: -8px;

            @include query-min($grid-m) {
                width: 21px;
                height: 21px;
                @include font-size(12px);
            }
        }
    }

    &__price {
        display: none;
        @include font-size(14px);

        @include query-min($grid-m) {
            display: block;
        }
    }

    &__content {
        display: none;

        background: #fff;
        border-radius: $radius;
        border: 1px solid #f0f0f0;
        flex-direction: column;
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 1000;

        transition: $transition;

        gap: 0;

        &::before {
            content: "";
            display: block;
            position: absolute;
            border-style: solid;
            border-color: #f0f0f0  ;
            border-width: 1px 0 0 1px;
            width: 12px;
            height: 12px;
            top: -8px;
            right: 28px;
            background: inherit;

            border-top-left-radius: $radius;

            transform: rotate(45deg);
        }

        .is-active & {
            display: flex;
        }
    }
}
