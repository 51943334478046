@font-face {
	font-family: "svg";
	font-display: block;
	src: url("frontend/fonts/svg.eot?680865137f7b6e2ae56b04416dc93d85?#iefix") format("embedded-opentype"),
url("frontend/fonts/svg.woff2?680865137f7b6e2ae56b04416dc93d85") format("woff2"),
url("frontend/fonts/svg.woff?680865137f7b6e2ae56b04416dc93d85") format("woff");
}

.svg {
	line-height: 1;
}

.svg:before {
	font-family: svg !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.svg-cart:before {
	content: "\f101";
}
.svg-facebook:before {
	content: "\f102";
}
.svg-help:before {
	content: "\f103";
}
.svg-instagram:before {
	content: "\f104";
}
.svg-phone:before {
	content: "\f105";
}
.svg-pinterest:before {
	content: "\f106";
}
.svg-pots:before {
	content: "\f107";
}
.svg-truck:before {
	content: "\f108";
}
