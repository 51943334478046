.box-phone {
	//svg {
	//	display: inline;
	//	width: 12px;
	//	margin-right: 3px;
	//}

	a {
		@include font-size(12px);

		display: flex;

		background: #dfdfdf;
		padding: 4px 11px;
		border-radius: $radius;
		color: #000;
		font-weight: normal;
		align-items: center;

		line-height: 1;
		gap: 4px;

		&:hover {
			text-decoration: none;
			background: #cccccc;
		}
	}

	@include query($width-layout) {
		display: none;
	}

}