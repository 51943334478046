.benefits-block {
    display: flex;
    justify-content: center;

    @include query(570px) {
        flex-direction: column;
    }

    &__item {
        flex: 0 calc(100% / 3 - 80px);
        margin-right: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;

        border-radius: $radius;
        background: #f3f3f3;

        padding: 42px;

        &:last-of-type {
            margin-right: 0;
        }

        @include query(1120px) {
            margin-right: 40px;
            flex: 0 calc(100% / 3 - 40px);
            padding: 30px;
        }

        @include query(790px) {
            padding: 28px 14px;
        }

        @include query(570px) {
            margin-right: 0;
            margin-bottom: $margin-gap;
        }


        &__icon {
            margin-bottom: 2rem;
            border: 2px solid $cl-green;

            width: 96px;
            height: 96px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            font-size: 41px;

            @include query(770px) {
                width: 48px;
                height: 48px;
                font-size: 20px;
            }

            @include query(570px) {
                width: 96px;
                height: 96px;
                font-size: 41px;
            }

            i {
                color: $cl-green;
            }
        }

        &__title {
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 1rem;
            color: #1b273d;
            line-height: 1.3;
            text-align: center;
        }

        &__description {
            color: #919191;
            line-height: 1.5;
            font-weight: normal;
            text-align: center;
            font-size: 14px;
        }
    }
}