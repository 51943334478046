// Variables
$aui-button-min-width: 0px !default;

$aui-button-font-family: $aui-font-family--primary !default;
$aui-button-font-size: $aui-font-size--base !default;
$aui-button-font-weight: $aui-font-weight--base !default;
$aui-button-text-transform: none !default;
$aui-button-letter-spacing: inherit !default;


$aui-button-subtext-font-family: $aui-font-family--primary !default;
$aui-button-subtext-font-size: $aui-font-size--sm !default;
$aui-button-subtext-font-weight: $aui-font-size--sm !default;
$aui-button-subtext-text-transform: none!default;

$aui-button-text-gap: 4px !default;
$aui-button-text-align-items: center !default;

$aui-button-border-width: 1px !default;
$aui-button-border-type: solid !default;
$aui-button-border-radius: $aui-border-radius--base !default;

$aui-button-padding--sm: $aui-padding--xs !default;
$aui-button-padding--base: $aui-padding--sm !default;
$aui-button-padding--lg: $aui-padding--base !default;

$aui-button-icon-size: $aui-button-font-size !default;
$aui-button-icon-scale: 1.05 !default;
$aui-button-image-width: 55px !default;

// Regular
$aui-button-darken--hover: 3% !default;
$aui-button-darken--focus: 5% !default;

$aui-button-regular-background: $aui-color-white !default;
$aui-button-regular-color: $aui-color-text !default;
$aui-button-regular-border-color: transparent !default;

$aui-button-regular-background--hover: darken(
  $aui-button-regular-background,
  $aui-button-darken--hover
) !default;
$aui-button-regular-color--hover: $aui-color-text !default;
$aui-button-regular-border-color--hover: darken(
  $aui-button-regular-border-color,
  $aui-button-darken--hover
) !default;

$aui-button-regular-background--focus: darken(
  $aui-button-regular-background,
  $aui-button-darken--focus
) !default;
$aui-button-regular-color--focus: $aui-color-text !default;
$aui-button-regular-border-color--focus: darken(
  $aui-button-regular-border-color,
  $aui-button-darken--focus
) !default;

$aui-button-regular-outline-background: $aui-color-white !default;
$aui-button-regular-outline-color: $aui-color-text !default;
$aui-button-regular-outline-border-color: $aui-color-border !default;

$aui-button-regular-outline-background--hover: darken(
  $aui-button-regular-outline-background,
  $aui-button-darken--hover
) !default;
$aui-button-regular-outline-color--hover: $aui-color-text !default;
$aui-button-regular-outline-border-color--hover: darken(
  $aui-button-regular-outline-border-color,
  $aui-button-darken--hover
) !default;

$aui-button-regular-outline-background--focus: darken(
  $aui-button-regular-outline-background,
  $aui-button-darken--focus
) !default;
$aui-button-regular-outline-color--focus: $aui-color-text !default;
$aui-button-regular-outline-border-color--focus: darken(
  $aui-button-regular-outline-border-color,
  $aui-button-darken--focus
) !default;

$aui-button-regular-text-color: $aui-color-text !default;

$aui-button-regular-text-color--hover: darken(
  $aui-button-regular-text-color,
  $aui-button-darken--hover
) !default;

$aui-button-regular-text-color--focus: darken(
  $aui-button-regular-text-color,
  $aui-button-darken--focus
) !default;

// Primary
$aui-button-primary-background: $aui-color-primary !default;
$aui-button-primary-color: $aui-color-white !default;
$aui-button-primary-border-color: transparent !default;

$aui-button-primary-background--hover: darken(
  $aui-button-primary-background,
  $aui-button-darken--hover
) !default;
$aui-button-primary-color--hover: $aui-button-primary-color !default;
$aui-button-primary-border-color--hover: darken(
  $aui-button-primary-border-color,
  $aui-button-darken--hover
) !default;

$aui-button-primary-background--focus: darken(
  $aui-button-primary-background,
  $aui-button-darken--focus
) !default;
$aui-button-primary-color--focus: $aui-button-primary-color !default;
$aui-button-primary-border-color--focus: darken(
  $aui-button-primary-border-color,
  $aui-button-darken--focus
) !default;

$aui-button-primary-outline-background: $aui-color-white !default;
$aui-button-primary-outline-color: $aui-color-primary !default;
$aui-button-primary-outline-border-color: $aui-color-border !default;

$aui-button-primary-outline-background--hover: darken(
  $aui-button-primary-outline-background,
  $aui-button-darken--hover
) !default;
$aui-button-primary-outline-color--hover: $aui-color-primary !default;
$aui-button-primary-outline-border-color--hover: darken(
  $aui-button-primary-outline-border-color,
  $aui-button-darken--hover
) !default;

$aui-button-primary-outline-background--focus: darken(
  $aui-button-primary-outline-background,
  $aui-button-darken--focus
) !default;
$aui-button-primary-outline-color--focus: $aui-color-primary !default;
$aui-button-primary-outline-border-color--focus: darken(
  $aui-button-primary-outline-border-color,
  $aui-button-darken--focus
) !default;

$aui-button-primary-text-color: $aui-color-primary !default;

$aui-button-primary-text-color--hover: darken(
  $aui-button-primary-text-color,
  $aui-button-darken--hover
) !default;

$aui-button-primary-text-color--focus: darken(
  $aui-button-primary-text-color,
  $aui-button-darken--focus
) !default;

// Secondary
$aui-button-secondary-background: $aui-color-secondary !default;
$aui-button-secondary-color: $aui-color-white !default;
$aui-button-secondary-border-color: transparent !default;

$aui-button-secondary-background--hover: darken(
  $aui-button-secondary-background,
  $aui-button-darken--hover
) !default;
$aui-button-secondary-color--hover: $aui-button-secondary-color !default;
$aui-button-secondary-border-color--hover: darken(
  $aui-button-secondary-border-color,
  $aui-button-darken--hover
) !default;

$aui-button-secondary-background--focus: darken(
  $aui-button-secondary-background,
  $aui-button-darken--focus
) !default;
$aui-button-secondary-color--focus: $aui-button-secondary-color !default;
$aui-button-secondary-border-color--focus: darken(
  $aui-button-secondary-border-color,
  $aui-button-darken--focus
) !default;

$aui-button-secondary-outline-background: $aui-color-white !default;
$aui-button-secondary-outline-color: $aui-color-secondary !default;
$aui-button-secondary-outline-border-color: $aui-color-border !default;

$aui-button-secondary-outline-background--hover: darken(
  $aui-button-secondary-outline-background,
  $aui-button-darken--hover
) !default;
$aui-button-secondary-outline-color--hover: $aui-color-secondary !default;
$aui-button-secondary-outline-border-color--hover: darken(
  $aui-button-secondary-outline-border-color,
  $aui-button-darken--hover
) !default;

$aui-button-secondary-outline-background--focus: darken(
  $aui-button-secondary-outline-background,
  $aui-button-darken--focus
) !default;
$aui-button-secondary-outline-color--focus: $aui-color-secondary !default;
$aui-button-secondary-outline-border-color--focus: darken(
  $aui-button-secondary-outline-border-color,
  $aui-button-darken--focus
) !default;

$aui-button-secondary-text-color: $aui-color-secondary !default;

$aui-button-secondary-text-color--hover: darken(
  $aui-button-secondary-text-color,
  $aui-button-darken--hover
) !default;

$aui-button-secondary-text-color--focus: darken(
  $aui-button-secondary-text-color,
  $aui-button-darken--focus
) !default;

// Danger
$aui-button-danger-background: $aui-color-danger !default;
$aui-button-danger-color: $aui-color-white !default;
$aui-button-danger-border-color: transparent !default;

$aui-button-danger-background--hover: darken(
  $aui-button-danger-background,
  $aui-button-darken--hover
) !default;
$aui-button-danger-color--hover: $aui-button-danger-color !default;
$aui-button-danger-border-color--hover: darken(
  $aui-button-danger-border-color,
  $aui-button-darken--hover
) !default;

$aui-button-danger-background--focus: darken(
  $aui-button-danger-background,
  $aui-button-darken--focus
) !default;
$aui-button-danger-color--focus: $aui-button-danger-color !default;
$aui-button-danger-border-color--focus: darken(
  $aui-button-danger-border-color,
  $aui-button-darken--focus
) !default;

$aui-button-danger-outline-background: $aui-color-white !default;
$aui-button-danger-outline-color: $aui-color-danger !default;
$aui-button-danger-outline-border-color: $aui-color-border !default;

$aui-button-danger-outline-background--hover: darken(
  $aui-button-danger-outline-background,
  $aui-button-darken--hover
) !default;
$aui-button-danger-outline-color--hover: $aui-color-danger !default;
$aui-button-danger-outline-border-color--hover: darken(
  $aui-button-danger-outline-border-color,
  $aui-button-darken--hover
) !default;

$aui-button-danger-outline-background--focus: darken(
  $aui-button-danger-outline-background,
  $aui-button-darken--focus
) !default;
$aui-button-danger-outline-color--focus: $aui-color-danger !default;
$aui-button-danger-outline-border-color--focus: darken(
  $aui-button-danger-outline-border-color,
  $aui-button-darken--focus
) !default;

$aui-button-danger-text-color: $aui-color-danger !default;

$aui-button-danger-text-color--hover: darken(
  $aui-button-danger-text-color,
  $aui-button-darken--hover
) !default;

$aui-button-danger-text-color--focus: darken(
  $aui-button-danger-text-color,
  $aui-button-darken--focus
) !default;

// Error
$aui-button-error-background: $aui-color-error !default;
$aui-button-error-color: $aui-color-white !default;
$aui-button-error-border-color: transparent !default;

$aui-button-error-background--hover: darken(
  $aui-button-error-background,
  $aui-button-darken--hover
) !default;
$aui-button-error-color--hover: $aui-button-error-color !default;
$aui-button-error-border-color--hover: darken(
  $aui-button-error-border-color,
  $aui-button-darken--hover
) !default;

$aui-button-error-background--focus: darken(
  $aui-button-error-background,
  $aui-button-darken--focus
) !default;
$aui-button-error-color--focus: $aui-button-error-color !default;
$aui-button-error-border-color--focus: darken(
  $aui-button-error-border-color,
  $aui-button-darken--focus
) !default;

$aui-button-error-outline-background: $aui-color-white !default;
$aui-button-error-outline-color: $aui-color-error !default;
$aui-button-error-outline-border-color: $aui-color-border !default;

$aui-button-error-outline-background--hover: darken(
  $aui-button-error-outline-background,
  $aui-button-darken--hover
) !default;
$aui-button-error-outline-color--hover: $aui-color-error !default;
$aui-button-error-outline-border-color--hover: darken(
  $aui-button-error-outline-border-color,
  $aui-button-darken--hover
) !default;

$aui-button-error-outline-background--focus: darken(
  $aui-button-error-outline-background,
  $aui-button-darken--focus
) !default;
$aui-button-error-outline-color--focus: $aui-color-error !default;
$aui-button-error-outline-border-color--focus: darken(
  $aui-button-error-outline-border-color,
  $aui-button-darken--focus
) !default;

$aui-button-error-text-color: $aui-color-error !default;

$aui-button-error-text-color--hover: darken(
  $aui-button-error-text-color,
  $aui-button-darken--hover
) !default;

$aui-button-error-text-color--focus: darken(
  $aui-button-error-text-color,
  $aui-button-darken--focus
) !default;

// Success
$aui-button-success-background: $aui-color-success !default;
$aui-button-success-color: $aui-color-white !default;
$aui-button-success-border-color: transparent !default;

$aui-button-success-background--hover: darken(
  $aui-button-success-background,
  $aui-button-darken--hover
) !default;
$aui-button-success-color--hover: $aui-button-success-color !default;
$aui-button-success-border-color--hover: darken(
  $aui-button-success-border-color,
  $aui-button-darken--hover
) !default;

$aui-button-success-background--focus: darken(
  $aui-button-success-background,
  $aui-button-darken--focus
) !default;
$aui-button-success-color--focus: $aui-button-success-color !default;
$aui-button-success-border-color--focus: darken(
  $aui-button-success-border-color,
  $aui-button-darken--focus
) !default;

// Info
$aui-button-info-background: $aui-color-info !default;
$aui-button-info-color: $aui-color-white !default;
$aui-button-info-border-color: transparent !default;

$aui-button-info-background--hover: darken(
  $aui-button-info-background,
  $aui-button-darken--hover
) !default;
$aui-button-info-color--hover: $aui-button-info-color !default;
$aui-button-info-border-color--hover: darken(
  $aui-button-info-border-color,
  $aui-button-darken--hover
) !default;

$aui-button-info-background--focus: darken(
  $aui-button-info-background,
  $aui-button-darken--focus
) !default;
$aui-button-info-color--focus: $aui-button-info-color !default;
$aui-button-info-border-color--focus: darken(
  $aui-button-info-border-color,
  $aui-button-darken--focus
) !default;

// Warning
$aui-button-warning-background: $aui-color-warning !default;
$aui-button-warning-color: $aui-color-white !default;
$aui-button-warning-border-color: transparent !default;

$aui-button-warning-background--hover: darken(
  $aui-button-warning-background,
  $aui-button-darken--hover
) !default;
$aui-button-warning-color--hover: $aui-button-warning-color !default;
$aui-button-warning-border-color--hover: darken(
  $aui-button-warning-border-color,
  $aui-button-darken--hover
) !default;

$aui-button-warning-background--focus: darken(
  $aui-button-warning-background,
  $aui-button-darken--focus
) !default;
$aui-button-warning-color--focus: $aui-button-warning-color !default;
$aui-button-warning-border-color--focus: darken(
  $aui-button-warning-border-color,
  $aui-button-darken--focus
) !default;

@import "./../css-variables/components/button";

// Styles
button:not(.aui-button) {
  &.is-active,
  &:hover {
    .aui-icon {
      svg,
      i {
        transform: scale(--aui-button-icon-scale);
      }
    }
  }
}

.aui-button {
  @include aui-transition-base;

  min-width: var(--aui-button-min-width);
  width: max-content;
  height: fit-content;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  gap: var(--aui-gap--sm);

  cursor: pointer;
  text-decoration: none;

  font-family: var(--aui-button-font-family);
  font-size: var(--aui-button-font-size);
  font-weight: var(--aui-button-font-weight);
  text-transform: var(--aui-button-text-transform);
  line-height: 1;
  letter-spacing: var(--aui-button-letter-spacing);

  border: var(--aui-button-border-width) var(--aui-button-border-type)
    transparent;
  border-radius: var(--aui-button-border-radius);

  padding: var(--aui-button-padding--base);

  &.is-active,
  &:hover {
    text-decoration: none;
  }

  .aui-icon {
    svg,
    i {
      width: var(--aui-button-icon-size);
      height: var(--aui-button-icon-size);
    }
  }

  img {
    width: var(--aui-button-image-width);
  }

  &__text {
    display: inline-flex;
    flex-direction: column;
    gap: var(--aui-button-text-gap);
    align-items: var(--aui-button-text-align-items);

    &-main {
      display: block;
    }
    &-sub {
      display: block;
      font-family: var(--aui-button-subtext-font-family);
      font-size: var(--aui-button-subtext-font-size);
      font-weight: var(--aui-button-subtext-font-weight);
      text-transform: var(--aui-button-subtext-text-transform);
    }
  }

  // Sizes
  &--sm {
    padding: var(--aui-button-padding--sm);
  }

  &--lg {
    padding: var(--aui-button-padding--lg);
  }

  // Regular
  &--regular {
    background: var(--aui-button-regular-background);
    color: var(--aui-button-regular-color);
    border-color: var(--aui-button-regular-border-color);

    &.is-active,
    &:hover {
      background: var(--aui-button-regular-background--hover);
      color: var(--aui-button-regular-color--hover);
      border-color: var(--aui-button-regular-border-color--hover);
    }

    &:focus {
      background: var(--aui-button-regular-background--focus);
      color: var(--aui-button-regular-color--focus);
      border-color: var(--aui-button-regular-border-color--focus);
    }
  }

  &--regular-outline {
    background: var(--aui-button-regular-outline-background);
    color: var(--aui-button-regular-outline-color);
    border-color: var(--aui-button-regular-outline-border-color);

    &.is-active,
    &:hover {
      background: var(--aui-button-regular-outline-background--hover);
      color: var(--aui-button-regular-outline-color--hover);
      border-color: var(--aui-button-regular-outline-border-color--hover);
    }

    &:focus {
      background: var(--aui-button-regular-outline-background--focus);
      color: var(--aui-button-regular-outline-color--focus);
      border-color: var(--aui-button-regular-outline-border-color--focus);
    }
  }

  &--regular-text {
    background: transparent;
    border: none;
    color: var(--aui-button-regular-text-color);

    &.is-active,
    &:hover {
      color: var(--aui-button-regular-text-color--hover);
    }

    &:focus {
      color: var(--aui-button-regular-text-color--focus);
    }

    padding: 0;
  }

  // Primary
  &--primary {
    background: var(--aui-button-primary-background);
    color: var(--aui-button-primary-color);
    border-color: var(--aui-button-primary-border-color);

    &.is-active,
    &:hover {
      background: var(--aui-button-primary-background--hover);
      color: var(--aui-button-primary-color--hover);
      border-color: var(--aui-button-primary-border-color--hover);
    }

    &:focus {
      background: var(--aui-button-primary-background--focus);
      color: var(--aui-button-primary-color--focus);
      border-color: var(--aui-button-primary-border-color--focus);
    }
  }

  &--primary-outline {
    background: var(--aui-button-primary-outline-background);
    color: var(--aui-button-primary-outline-color);
    border-color: var(--aui-button-primary-outline-border-color);

    &.is-active,
    &:hover {
      background: var(--aui-button-primary-outline-background--hover);
      color: var(--aui-button-primary-outline-color--hover);
      border-color: var(--aui-button-primary-outline-border-color--hover);
    }

    &:focus {
      background: var(--aui-button-primary-outline-background--focus);
      color: var(--aui-button-primary-outline-color--focus);
      border-color: var(--aui-button-primary-outline-border-color--focus);
    }
  }

  &--primary-text {
    background: transparent;
    color: var(--aui-button-primary-text-color);
    border: none;

    padding: 0;

    &.is-active,
    &:hover {
      color: var(--aui-button-primary-text-color--hover);
    }

    &:focus {
      color: var(--aui-button-primary-text-color--focus);
    }
  }

  // Secondary
  &--secondary {
    background: var(--aui-button-secondary-background);
    color: var(--aui-button-secondary-color);
    border-color: var(--aui-button-secondary-border-color);

    &.is-active,
    &:hover {
      background: var(--aui-button-secondary-background--hover);
      color: var(--aui-button-secondary-color--hover);
      border-color: var(--aui-button-secondary-border-color--hover);
    }

    &:focus {
      background: var(--aui-button-secondary-background--focus);
      color: var(--aui-button-secondary-color--focus);
      border-color: var(--aui-button-secondary-border-color--focus);
    }
  }

  &--secondary-outline {
    background: var(--aui-button-secondary-outline-background);
    color: var(--aui-button-secondary-outline-color);
    border-color: var(--aui-button-secondary-outline-border-color);

    &.is-active,
    &:hover {
      background: var(--aui-button-secondary-outline-background--hover);
      color: var(--aui-button-secondary-outline-color--hover);
      border-color: var(--aui-button-secondary-outline-border-color--hover);
    }

    &:focus {
      background: var(--aui-button-secondary-outline-background--focus);
      color: var(--aui-button-secondary-outline-color--focus);
      border-color: var(--aui-button-secondary-outline-border-color--focus);
    }
  }

  &--secondary-text {
    background: transparent;
    color: var(--aui-button-secondary-text-color);
    border: none;

    padding: 0;

    &.is-active,
    &:hover {
      color: var(--aui-button-secondary-text-color--hover);
    }

    &:focus {
      color: var(--aui-button-secondary-text-color--focus);
    }
  }

  // Danger
  &--danger {
    background: var(--aui-button-danger-background);
    color: var(--aui-button-danger-color);
    border-color: var(--aui-button-danger-border-color);

    &.is-active,
    &:hover {
      background: var(--aui-button-danger-background--hover);
      color: var(--aui-button-danger-color--hover);
      border-color: var(--aui-button-danger-border-color--hover);
    }

    &:focus {
      background: var(--aui-button-danger-background--focus);
      color: var(--aui-button-danger-color--focus);
      border-color: var(--aui-button-danger-border-color--focus);
    }
  }

  &--danger-outline {
    background: var(--aui-button-danger-outline-background);
    color: var(--aui-button-danger-outline-color);
    border-color: var(--aui-button-danger-outline-border-color);

    &.is-active,
    &:hover {
      background: var(--aui-button-danger-outline-background--hover);
      color: var(--aui-button-danger-outline-color--hover);
      border-color: var(--aui-button-danger-outline-border-color--hover);
    }

    &:focus {
      background: var(--aui-button-danger-outline-background--focus);
      color: var(--aui-button-danger-outline-color--focus);
      border-color: var(--aui-button-danger-outline-border-color--focus);
    }
  }

  &--danger-text {
    background: transparent;
    color: var(--aui-button-danger-text-color);
    border: none;

    padding: 0;

    &.is-active,
    &:hover {
      color: var(--aui-button-danger-text-color--hover);
    }

    &:focus {
      color: var(--aui-button-danger-text-color--focus);
    }
  }

  // Error
  &--error {
    background: var(--aui-button-error-background);
    color: var(--aui-button-error-color);
    border-color: var(--aui-button-error-border-color);

    &.is-active,
    &:hover {
      background: var(--aui-button-error-background--hover);
      color: var(--aui-button-error-color--hover);
      border-color: var(--aui-button-error-border-color--hover);
    }

    &:focus {
      background: var(--aui-button-error-background--focus);
      color: var(--aui-button-error-color--focus);
      border-color: var(--aui-button-error-border-color--focus);
    }
  }

  &--error-outline {
    background: var(--aui-button-error-outline-background);
    color: var(--aui-button-error-outline-color);
    border-color: var(--aui-button-error-outline-border-color);

    &.is-active,
    &:hover {
      background: var(--aui-button-error-outline-background--hover);
      color: var(--aui-button-error-outline-color--hover);
      border-color: var(--aui-button-error-outline-border-color--hover);
    }

    &:focus {
      background: var(--aui-button-error-outline-background--focus);
      color: var(--aui-button-error-outline-color--focus);
      border-color: var(--aui-button-error-outline-border-color--focus);
    }
  }

  &--error-text {
    background: transparent;
    color: var(--aui-button-error-text-color);
    border: none;

    padding: 0;

    &.is-active,
    &:hover {
      color: var(--aui-button-error-text-color--hover);
    }

    &:focus {
      color: var(--aui-button-error-text-color--focus);
    }
  }

  // Success
  &--success {
    background: var(--aui-button-success-background);
    color: var(--aui-button-success-color);
    border-color: var(--aui-button-success-border-color);

    &.is-active,
    &:hover {
      background: var(--aui-button-success-background--hover);
      color: var(--aui-button-success-color--hover);
      border-color: var(--aui-button-success-border-color--hover);
    }

    &:focus {
      background: var(--aui-button-success-background--focus);
      color: var(--aui-button-success-color--focus);
      border-color: var(--aui-button-success-border-color--focus);
    }
  }

  // Info
  &--info {
    background: var(--aui-button-info-background);
    color: var(--aui-button-info-color);
    border-color: var(--aui-button-info-border-color);

    &.is-active,
    &:hover {
      background: var(--aui-button-info-background--hover);
      color: var(--aui-button-info-color--hover);
      border-color: var(--aui-button-info-border-color--hover);
    }

    &:focus {
      background: var(--aui-button-info-background--focus);
      color: var(--aui-button-info-color--focus);
      border-color: var(--aui-button-info-border-color--focus);
    }
  }

  // Warning
  &--warning {
    background: var(--aui-button-warning-background);
    color: var(--aui-button-warning-color);
    border-color: var(--aui-button-warning-border-color);

    &.is-active,
    &:hover {
      background: var(--aui-button-warning-background--hover);
      color: var(--aui-button-warning-color--hover);
      border-color: var(--aui-button-warning-border-color--hover);
    }

    &:focus {
      background: var(--aui-button-warning-background--focus);
      color: var(--aui-button-warning-color--focus);
      border-color: var(--aui-button-warning-border-color--focus);
    }
  }

  // Disabled
  &--disabled,
  &[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
  }
}
