/*
	varianty bez nutnosti psát ke každému inputu v html třídu
	--- jinak je možno přidat třídy jako .input-field pro specifické inputy a BEM bude fungovat skvěle
*/

.input {
	height: $input-height;
	line-height: $input-height;
	padding: 0 15px;
	width: 100%;

	background: $input-bg;
	border: 1px solid $input-border;
	color: $input-font-color;

	@include animate(200);
	@include radius($radius);

	&:hover {

	}

	&:focus {
		background: darken($input-bg, 5%);
		border-color: darken($input-border, 5%);
	}
}

textarea {
	height: $input-height * 3;
	line-height: $input-height;
	padding: 5px 15px;
	width: 100%;

	border: 1px solid $input-border;
	background: $input-bg;

	@include animate(200);
	@include radius($radius);

	&:focus {
		background: darken($input-bg, 5%);
		border-color: darken($input-border, 5%);
	}
}


select {
	width: 100%;
	padding: 5px 15px;

	border: 1px solid $input-border;
	background: $input-bg;

	@include animate(200);
	@include radius($radius);
}

.input-radio {
	background-color: transparent;
	border: 0px;
}

.input-checkbox {
	padding:1px;
	height:13px;
	width:13px;

	border:0px;
	background:none;
	vertical-align:middle;
}
