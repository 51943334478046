.announcement-block {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 35px 7px 14px;
    @include font-size(12px);
    background-color: #c30f16;
    color: $cl-white;
    text-decoration: none;

    @include query(570px) {
        text-align: center;
    }

    &__close {
        position: absolute;
        right: 7px;
        top: calc(50% - 10px);
        display: block;
        width: 20px;
        height: 20px;
        color: $cl-white;
        font-size: 21px;
        text-align: center;
        line-height: 21px;
        border: none;
        background: transparent;

        &:hover {
            text-decoration: none;
        }
    }

    &__button {
        height: auto;
        padding: 6px 7px;
        font-size: 1.4rem;
        line-height: 1.4rem;
        margin-left: 7px;
    }

    p {
        margin-bottom: 0;
        margin-top: 0;
        font-weight: 400;
        font-size: 1.4rem;

        strong {
            font-weight: 700;
        }

        a {
             font-weight: 700;
            text-decoration: underline;
            color: $cl-white;

            &:hover {
                text-decoration: none;
            }
        }
    }

}