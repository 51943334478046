/*************************************************************************************
*  HELPERS
**************************************************************************************/
@include query($grid-m) {

	// TEXT
	.text-right-tab {text-align: right}
	.text-left-tab {text-align: left}
	.text-center-tab {text-align: center}
	.text-justify-tab {text-align: justify}

	.text-underline-tab {text-decoration: underline}
	.text-no-decoration-tab {text-decoration: none}
	.text-strike-tab {text-decoration: line-through}

	.text-no-wrap-tab {white-space: nowrap}
	.text-wrap-tab {word-wrap: break-word}

	// MARGIN
	.m0-tab {margin: 0px}
	.m5-tab {margin: 5px}
	.m10-tab {margin: 10px}
	.m15-tab {margin: 15px}
	.m20-tab {margin: 20px}
	.m25-tab {margin: 25px}
	.m30-tab {margin: 30px}
	.m35-tab {margin: 35px}
	.m40-tab {margin: 40px}
	.m45-tab {margin: 45px}
	.m50-tab {margin: 50px}
	.m55-tab {margin: 55px}
	.m60-tab {margin: 60px}

	.ml0-tab {margin-left: 0px}
	.ml5-tab {margin-left: 5px}
	.ml10-tab {margin-left: 10px}
	.ml15-tab {margin-left: 15px}
	.ml20-tab {margin-left: 20px}
	.ml25-tab {margin-left: 25px}
	.ml30-tab {margin-left: 30px}
	.ml35-tab {margin-left: 35px}
	.ml40-tab {margin-left: 40px}
	.ml45-tab {margin-left: 45px}
	.ml50-tab {margin-left: 50px}
	.ml55-tab {margin-left: 55px}
	.ml60-tab {margin-left: 60px}

	.mr0-tab {margin-right: 0px}
	.mr5-tab {margin-right: 5px}
	.mr10-tab {margin-right: 10px}
	.mr15-tab {margin-right: 15px}
	.mr20-tab {margin-right: 20px}
	.mr25-tab {margin-right: 25px}
	.mr30-tab {margin-right: 30px}
	.mr35-tab {margin-right: 35px}
	.mr40-tab {margin-right: 40px}
	.mr45-tab {margin-right: 45px}
	.mr50-tab {margin-right: 50px}
	.mr55-tab {margin-right: 55px}
	.mr60-tab {margin-right: 60px}

	.mt0-tab {margin-top: 0px}
	.mt5-tab {margin-top: 5px}
	.mt10-tab {margin-top: 10px}
	.mt15-tab {margin-top: 15px}
	.mt20-tab {margin-top: 20px}
	.mt25-tab {margin-top: 25px}
	.mt30-tab {margin-top: 30px}
	.mt35-tab {margin-top: 35px}
	.mt40-tab {margin-top: 40px}
	.mt45-tab {margin-top: 45px}
	.mt50-tab {margin-top: 50px}
	.mt55-tab {margin-top: 55px}
	.mt60-tab {margin-top: 60px}

	.mb0-tab {margin-bottom: 0px}
	.mb5-tab {margin-bottom: 5px}
	.mb10-tab {margin-bottom: 10px}
	.mb15-tab {margin-bottom: 15px}
	.mb20-tab {margin-bottom: 20px}
	.mb25-tab {margin-bottom: 25px}
	.mb30-tab {margin-bottom: 30px}
	.mb35-tab {margin-bottom: 35px}
	.mb40-tab {margin-bottom: 40px}
	.mb45-tab {margin-bottom: 45px}
	.mb50-tab {margin-bottom: 50px}
	.mb55-tab {margin-bottom: 55px}
	.mb60-tab {margin-bottom: 60px}

	// PADDING
	.p0-tab {padding: 0px}
	.p5-tab {padding: 5px}
	.p10-tab {padding: 10px}
	.p15-tab {padding: 15px}
	.p20-tab {padding: 20px}
	.p25-tab {padding: 25px}
	.p30-tab {padding: 30px}
	.p35-tab {padding: 35px}
	.p40-tab {padding: 40px}
	.p45-tab {padding: 45px}
	.p50-tab {padding: 50px}
	.p55-tab {padding: 55px}
	.p60-tab {padding: 60px}

	.pl0-tab {padding-left: 0px}
	.pl5-tab {padding-left: 5px}
	.pl10-tab {padding-left: 10px}
	.pl15-tab {padding-left: 15px}
	.pl20-tab {padding-left: 20px}
	.pl25-tab {padding-left: 25px}
	.pl30-tab {padding-left: 30px}
	.pl35-tab {padding-left: 35px}
	.pl40-tab {padding-left: 40px}
	.pl45-tab {padding-left: 45px}
	.pl50-tab {padding-left: 50px}
	.pl55-tab {padding-left: 55px}
	.pl60-tab {padding-left: 60px}

	.pr0-tab {padding-right: 0px}
	.pr5-tab {padding-right: 5px}
	.pr10-tab {padding-right: 10px}
	.pr15-tab {padding-right: 15px}
	.pr20-tab {padding-right: 20px}
	.pr25-tab {padding-right: 25px}
	.pr30-tab {padding-right: 30px}
	.pr35-tab {padding-right: 35px}
	.pr40-tab {padding-right: 40px}
	.pr45-tab {padding-right: 45px}
	.pr50-tab {padding-right: 50px}
	.pr55-tab {padding-right: 55px}
	.pr60-tab {padding-right: 60px}

	.pt0-tab {padding-top: 0px}
	.pt5-tab {padding-top: 5px}
	.pt10-tab {padding-top: 10px}
	.pt15-tab {padding-top: 15px}
	.pt20-tab {padding-top: 20px}
	.pt25-tab {padding-top: 25px}
	.pt30-tab {padding-top: 30px}
	.pt35-tab {padding-top: 35px}
	.pt40-tab {padding-top: 40px}
	.pt45-tab {padding-top: 45px}
	.pt50-tab {padding-top: 50px}
	.pt55-tab {padding-top: 55px}
	.pt60-tab {padding-top: 60px}

	.pb0-tab {padding-bottom: 0px}
	.pb5-tab {padding-bottom: 5px}
	.pb10-tab {padding-bottom: 10px}
	.pb15-tab {padding-bottom: 15px}
	.pb20-tab {padding-bottom: 20px}
	.pb25-tab {padding-bottom: 25px}
	.pb30-tab {padding-bottom: 30px}
	.pb35-tab {padding-bottom: 35px}
	.pb40-tab {padding-bottom: 40px}
	.pb45-tab {padding-bottom: 45px}
	.pb50-tab {padding-bottom: 50px}
	.pb55-tab {padding-bottom: 55px}
	.pb60-tab {padding-bottom: 60px}

	// FLOAT + CLEAR
	.clear-tab {clear: both}
	.clear-r-tab {clear: right}
	.clear-l-tab {clear: left}

	.left-tab {float: left}
	.right-tab {float: right}
	.fnone-tab {float: none}

	// DISPLAY
	.display-inline-tab {display: inline !important;}
	.display-inline-block-tab {display: inline-block !important;}
	.display-block-tab {display: block !important;}
	.display-none-tab {display: none !important;}
}