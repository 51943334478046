.header {
	position: relative;
	display: flex;
	flex-direction: column;

	@include query($grid-l) {
		padding: 8px;		
	}

	&--pinned {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1002;
		background: #fff;
		box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
	}

	.header-right-wrap {
		width: 80%;
		display: flex;
		justify-content: space-around;
		align-items: center;

		@include query($grid-l) {
			order: 2;
			justify-content: flex-end;
			width: auto;
		}
	}

	&__top {
	
		background: $cl-light;

		.wrap {
			display: flex;
			justify-content: space-between;
			padding: 5px 20px;
			align-items: center;		
		}

		@include query($grid-l) {
			display: none;
		}
		.header--pinned &{
			display: none;
		}
	}

	&__bottom {
	
		.wrap {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: 30px 20px 21px;
			align-items: center;
			gap: 20px;

			@include query($grid-l) {
				padding: 0;
			}	
		}

	}

	@include query-min($width-layout){
		&.fix {
			position: fixed;
			top: 0;
			width: 940px;
			background-color: white;
			z-index: 9999;

			.header__top {
				display: none;
			}

			.header-logo {
				margin-top: 23px;
			}

			.header-basket {
				margin-top: 20px;
			}
		}
	}
}