.widget__gallery {
    width: 140%;
    margin: 0 -20%;
    flex-wrap: no-wrap;
    justify-content: center;

    @include query(940px) {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    &-item {
        margin: 10px;
        max-width: 200px;
        min-width: 70px;
        width: 20%;

        img {max-width: 100%;}
    }

    @include query(1120px) {
        width: 100%;
        margin: 0;
    }
}