.header-navTop {
	position: relative;
	display: inline-block;
	margin-left: -20px;

	@include query($width-layout) {
		margin-top: 7px
	}

	ul {
		@include reset-ul();
	}

	& > ul {
		@include query($width-layout){
			display: none;
		}
		@include query-min($width-layout){
			display: block !important;
		}
	}

	li {
		position: relative;
		display: inline-block;
		@include query($width-layout){
			display: block;
		}

		&.parent > a:after {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			content: "";
			margin-left: 8px;
			@include sprite(navTop-arrow);
		}

		&:hover {
			ul {
				display: block;
				border-radius: $radius;

				@include query($width-layout){
					display: none;
				}
			}
		}
		a {
			position: relative;
			display: block;
			padding: 0 20px;
			color: #2a2a2a;
			@include font-size(13px);
			line-height: 40px;

			@include query(1050px){
				line-height: 26px;
				padding: 0 10px;
			}

			&:hover {
				text-decoration: underline;
			}
		}

		ul {
			position: absolute;
			text-align: center;
			background-color: white;
			z-index: 999;
			right: 17px;
			left: 0;
			padding: 0 9px;
			display: none;
			top: 100%;
			border-bottom: 3px solid #528D51;
			width: 110%;
			box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);

			li {
				display: block;
				border-top: 1px solid #E1E1E1;

				&.first {
					border-top: none;
				}

				a {
					line-height: 42px;
					@include font-size(14px);
					text-align: left;

					&::after {
						display: none;
					}
				}
			}
		}
	}

	&__multi > a:after {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		content: "";
		margin-left: 8px;
		@include sprite(navTop-arrow);

		@include query($width-layout){
			display: none;
		}
	}
}