body {
    &.aui-loader-overlay,
    &:has(.aui-modal--lock-scroll) {
        max-height: 100dvh;
        overflow: hidden;
    }
}


.aui-breadcrumbs {
    li {
        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        &::after {
            content: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="16" height="16" viewBox="0 0 32 32"%3E%3Cpath fill="%23437c38" d="M22 16L12 26l-1.4-1.4l8.6-8.6l-8.6-8.6L12 6z"%2F%3E%3C%2Fsvg%3E');
            margin-top: 3px;
        }

        &:last-child {
            & > * {
                font-weight: normal;
            }
        }
    }
}

.aui-loader-overlay {
    &__mask {
        &-content {
            h2 {
                font-size: 18px;
            }
        }
    }
}

.aui-modal.is-success {
    .aui-modal__container {
        border-color: $cl-green;
    }

    .aui-modal__container-header {
        background: $cl-green;
        color: $cl-white;

        .aui-button {
            color: $cl-white;
        }
    }
}

