// Box
$box-sizing: border-box;

// Paths
$path-font: '/assets/fonts';
$path-css-images: '/static/images';

// Widths
$width-layout: 940px;
$width-content: 745px;
$width-panel: 230px;
$width-layout-wide: 1600px;

// Fonts
$font-base: 'Open Sans', 'Open Sans-fallback', sans-serif;

$font-size-small: 14px;
$font-size: 16px;
$font-size-big: 18px;

// Colors
$cl-black: #000;
$cl-white: #fff;
$cl-dark: #252525;
$cl-light: #f3f3f3;
$cl-grey: #ccc;

$cl-red: #d63636;
$cl-blue: #1082b5;
$cl-green: #407b32;
$cl-orange: #ffa500;

$cl-cta: #65BB51;
$cl-cta2: $cl-blue;

$cl-base: #212529;

// Background
$bg: $cl-white;
$bg-box: $cl-light;
$bg-table: $cl-light;

// Gap
$margin-gap: 20px;

// Radius
$radius: 5px;
$radius-big: $radius * 2;

// Buttons
$btn-bg: $cl-white;
$btn-border: $cl-grey;
$btn-color: $cl-black;

$btn-bg-hover: darken($btn-bg, 15%);
$btn-border-hover: darken($btn-border, 10%);

$btn-height: 5rem;
$btn-border-width: 1px;

$btn-small-height: 3rem;

// Inputs
$input-height: $btn-height;
$input-border: $cl-grey;
$input-bg: $cl-light;
$input-font-color: $cl-black;

$input-err-border: $cl-red;
$input-err-bg: lighten($input-err-border, 30%);

// Transitions
$transition: cubic-bezier(.61, .2, .48, 1.01);
$transition-time: .3s;

// Breakpoints
$grid-s: 480 - 1;
$grid-m: 600 - 1;
$grid-l: $width-layout - 1;
$grid-xl: $width-layout-wide - 1;

