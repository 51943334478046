.header-logo {
	position: relative;
	display: inline-block;
	text-indent: -9999rem;
	max-width: 280px;
	width: 20%;

	@include query($grid-l) {
		max-width: 220px;
		width: 100%;
		padding-left: 20px;
	}

	img {
		display: block;
		max-width: 100%;
	}

	&:hover{
		@include opacity(0.7);
	}

	@include query($width-layout){
		order: 1;
	}
}