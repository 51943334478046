@mixin center-image($border: 0) {
  position: absolute;
  top: $border;
  bottom: $border;
  right: $border;
  left: $border;
  margin: auto;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

@mixin radius($radius:$radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

// css3 animace (vetsinou na odkazy)
@mixin animate($sek:250, $anim:all, $tf:ease) {
  -webkit-transition: $anim $sek+ms $tf;
  -moz-transition: $anim $sek+ms $tf;
  transition: $anim $sek+ms $tf;
}

@mixin perspective($value) {
  -webkit-perspective: $value;
  -moz-perspective: $value;
  -o-perspective: $value;
  perspective: $value;
}

@mixin backface() {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}

@mixin preserve() {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

// pruhlednost
@mixin opacity($op) {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+$op*100+")"; // IE 8
  filter: alpha(opacity=$op*100); // IE 5-7
  -moz-opacity: $op; // Netscape
  -khtml-opacity: $op; // Safari 1.x
  opacity: $op; // modern browsers
}

//stin
@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin box-double-shadow($shadow1,$shadow2) {
  -webkit-box-shadow: $shadow1, $shadow2;
  -moz-box-shadow: $shadow1, $shadow2;
  box-shadow: $shadow1, $shadow2;
}

@mixin text-shadow($shadow) {
  text-shadow: $shadow;
  -webkit-text-shadow: $shadow;
  -moz-text-shadow: $shadow;
  -ms-text-shadow: $shadow;
  -o-text-shadow: $shadow;
}

@mixin rotatey($deg:180) {
  -webkit-transform: rotateY($deg+deg);
  -moz-transform: rotateY($deg+deg);
  transform: rotateY($deg+deg);
  -ms-transform: rotateY($deg+deg);
  -o-transform: rotateY($deg+deg);
}

@mixin rotate($deg:180) {
  -webkit-transform: rotate($deg+deg);
  -moz-transform: rotate($deg+deg);
  -ms-transform: rotate($deg+deg);
  -o-transform: rotate($deg+deg);
  transform: rotate($deg+deg);
}

// conditions for IE9
@mixin ie9 {
  .ie9 & {
    @content
  }
}

// conditions for IE8 and below
@mixin oldie {
  .oldie & {
    @content
  }
}

// conditions for IE8 + IE7 and below
@mixin ie7 {
  .oldie.ie7 & {
    @content
  }
}


@mixin align($side:ha) {
  @if $side == "ha" { //zarovnani vertikalne
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    @include oldie {
      top: auto;
    }
    /* ie8 fix */
  } @else if $side == "none" { // zruseni zarovnani
    top: auto;
    left: auto;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  } @else { //zarovnani vertikalni i horizontalni
    top: 50%;
    left: 50%;
    transform: translate(-50%) translateY(-50%);
    -webkit-transform: translate(-50%) translateY(-50%);
    -moz-transform: translate(-50%) translateY(-50%);
    -o-transform: translate(-50%) translateY(-50%);
    -ms-transform: translate(-50%) translateY(-50%);
    @include oldie {
      top: auto;
      left: auto;
    }
    /* ie8 fix */
  }
}

// parallax
@mixin parallax($check:yes) {
  @if $check == "none" {
    -webkit-background-attachment: initial;
    -moz-background-attachment: initial;
    -ms-background-attachment: initial;
    -o-background-attachment: initial;
    background-attachment: initial;
  } @else {
    -webkit-background-attachment: fixed;
    -moz-background-attachment: fixed;
    -ms-background-attachment: fixed;
    -o-background-attachment: fixed;
    background-attachment: fixed;
  }
}

// css zvetseni objektu
@mixin scale($value:1.5) {
  transform: scale($value);
  -webkit-transform: scale($value);
  -moz-transform: scale($value);
  -o-transform: scale($value);
  -ms-transform: scale($value);
}

// rgba pozadi
@mixin rgba($color,$alpha) {
  background-color: $color;
  background-color: rgba(red($color), green($color), blue($color), $alpha/100);
}

// Gradient s nastavením procent
@mixin gradient($start-color:#fff, $start-perc: 0%, $end-color:#000, $end-perc: 100%) {
  background: $start-color;
  background: -moz-linear-gradient(top, $start-color $start-perc, $end-color $end-perc);
  background: -webkit-gradient(linear, left top, left bottom, color-stop($start-perc, $start-color), color-stop($end-perc, $end-color));
  background: -webkit-linear-gradient(top, $start-color $start-perc, $end-color $end-perc);
  background: -o-linear-gradient(top, $start-color $start-perc, $end-color $end-perc);
  background: -ms-linear-gradient(top, $start-color $start-perc, $end-color $end-perc);
  background: linear-gradient(to bottom, $start-color $start-perc, $end-color $end-perc);
  filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$start-color}', endColorstr='#{$end-color}', GradientType=0)";
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$start-color}', endColorstr='#{$end-color}', GradientType=0)";
}

// Gradient horizontální
@mixin gradient-horizontal($start-color, $end-color) {
  background: $start-color;
  background: -moz-linear-gradient(left, $start-color 0%, $end-color 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, $start-color), color-stop(100%, $end-color));
  background: -webkit-linear-gradient(left, $start-color 0%, $end-color 100%);
  background: -o-linear-gradient(left, $start-color 0%, $end-color 100%);
  background: -ms-linear-gradient(left, $start-color 0%, $end-color 100%);
  background: linear-gradient(to right, $start-color 0%, $end-color 100%);
  filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$start-color}', endColorstr='#{$end-color}', GradientType=0)";
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$start-color}', endColorstr='#{$end-color}', GradientType=0)";
}

@mixin box-sizing($sizing) {
  -webkit-box-sizing: $sizing;
  -moz-box-sizing: $sizing;
  -ms-box-sizing: $sizing;
  box-sizing: $sizing;
}

// Zápis width a height - čtverec
@mixin square($size) {
  width: $size;
  height: $size;
}

// Zápis width a height - obdélník
@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin transition($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  -o-transform: $transform;
  transform: $transform;
}

@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
  -moz-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
  -o-transform: translate($x, $y);
  transform: translate($x, $y);
}

@mixin skew($x, $y) {
  -webkit-transform: skew($x, $y);
  -moz-transform: skew($x, $y);
  -ms-transform: skew($x, $y);
  -o-transform: skew($x, $y);
  transform: skew($x, $y);
}

@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
  -moz-transform: translate3d($x, $y, $z);
  -o-transform: translate3d($x, $y, $z);
  transform: translate3d($x, $y, $z);
}


// Background clip
@mixin background-clip($clip) {
  background-clip: $clip;
  -moz-background-clip: $clip;
  -webkit-background-clip: $clip;
}

// ************************************************************************************
//  MIXINS - vlastni
// **************************************************************************************/

@mixin reset-ul() {
  padding: 0;
  margin: 0;
  list-style: none;
}

// style pro webkit prohlizece
@mixin webkit { // css rules for webkit (chrome and safari)
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    @content
  }
}

// letter spacing
@mixin ls($value) {
  letter-spacing: $value/1000+em;
}

//prepocet media queries z px do em jednotek
@function px-to-em-query($value) {
  $value: $value / ($value * 0 + 1); //remove any units after number
  @return ($value/16)*1em;
}

//responsive
@mixin query($value) {
  @media screen and (max-width: px-to-em-query($value)) {
    @content
  }
}

//responsive
@mixin query-min($value) {
  @media screen and (min-width: px-to-em-query($value)) {
    @content
  }
}


// fix loga po najeti mysi
@mixin imghf { // img hover fix in firefox
  -webkit-transform: translate3d(0px, 0px, 0px);
  -moz-transform: translate3d(0px, 0px, 0px);
  -o-transform: translate3d(0px, 0px, 0px);
  -ms-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

// Resetování ul nebo ol listu
@mixin reset-ul() {
  padding: 0;
  margin: 0;
  list-style: none;
}


// Nastavení centrování elementu, defaultně i horizontáně, parametrem lze horizontální změnit
@mixin center-it($text-align: center) {
  display: table-cell;
  vertical-align: middle;
  text-align: $text-align;
}


// Nastavení transition délka např 0.2s, a funkce např linear - defaultně nastavení z variables
@mixin set-transition($this-time: $transition-time, $this-function: $transition) {
  transition-timing-function: $this-function !important;
  transition-duration: $this-time !important;
}


// Clearfix
@mixin clearfix() {
  *zoom: 1;
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}


// Nastavení background image, cesta do design se nastavuje sama, dále pozice a repeat, default hodnoty nastaveny
@mixin bg($background-url: none, $background-position: 0 0, $background-repeat: no-repeat) {
  background-image: url('#{$path-css-images}/#{$background-url}');
  background-repeat: $background-repeat;
  background-position: $background-position;
}

// vypln pozadi
@mixin cover() {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

// Skrytí scrollbaru ve všech prohlížečích
@mixin scrollbar-hide() {
  overflow: auto;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    display: none;
  }
}


// Nastavuje výšku řádku a počet řádků, kolik si element bude udržovat a kde text usekne
@mixin eliminate-lines($height-line, $count-line) {
  height: $height-line * $count-line;
  line-height: $height-line;
  overflow: hidden;
}


// Nastavuje elementu stejnou výšku a stejnou výšku řádku
@mixin h-lh($height) {
  height: $height;
  line-height: $height;
}

// Nastavení pro fontface, načte font, stačí mu dodat název a  název souboru, cesta je nastavena z variables
@mixin font-face($font-name, $font-file, $font-weight: normal, $font-style: normal) {

  @font-face {
    font-family: '#{$font-name}';
    src: url('#{$path-font}/#{$font-file}.eot');
    src: url('#{$path-font}/#{$font-file}.eot?#iefix') format('embedded-opentype'),
    url('#{$path-font}/#{$font-file}.woff2') format('woff2'),
    url('#{$path-font}/#{$font-file}.woff') format('woff'),
    url('#{$path-font}/#{$font-file}.ttf') format('truetype'),
    url('#{$path-font}/#{$font-file}.svg##{$font-name}') format('svg');
    font-weight: $font-weight;
    font-style: $font-style;

  }
  @include webkit {
    @font-face {
      font-family: '#{$font-name}';
      src: url('#{$path-font}/#{$font-file}.svg') format('svg');
    }
  }

}

