$cart-detail-width: 320px;
$cart-detail-padding: px;

$cart-detail-item-count: 5;
$cart-detail-item-height: 85px;

.cart-detail {
    @include reset-ul();
    display: flex;
    flex-direction: column;
    max-height: $cart-detail-item-count*$cart-detail-item-height;
    width: $cart-detail-width;
    overflow-y: auto;

    &__empty-message {
        padding: 27px;
    }

    &__items {
        @include reset-ul();

        &__item {
            display: flex;
            flex-direction: row;
            width: 100%;
            min-height: $cart-detail-item-height;

            border-bottom: 1px solid #f0f0f0;

            &__image {
                position: relative;
                width: 71px;

                img {
                    @include center-image();
                }
            }

            &__content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex: 1;
                padding-right: 16px;
                z-index: 1000;

                &__title {
                    display: inline-block;
                    padding-bottom: 5px;

                    @include font-size(12px);
                   // color: red;
                    line-height: 1.5;
                    text-decoration: none;
                }

                &__prices {
                    display: flex;
                    width: 100%;
                    padding-bottom: 5px;
                    justify-content: space-between;
                    align-items: center;

                    &__unit {
                        @include font-size(12px);
                       // color: red;
                    }

                    &__total {
                        @include font-size(14px);
                        font-weight: 700;
                      //  color: red;
                    }
                }
            }
        }
    }

    &__bottom {
        &__button {
            .btn {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
    }
}