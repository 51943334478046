
// Variables
$aui-loader-overlay-background: $aui-color-white !default;
$aui-loader-overlay-color: $aui-color-primary !default;
$aui-loader-overlay-font-size: revert !default;;
$aui-loader-overlay-font-weight: $aui-font-weight--base;

$aui-loader-overlay-icon-color: $aui-color-primary !default;
$aui-loader-overlay-icon-max-size: 150px !default;;
$aui-loader-overlay-icon-animation: loading-rotate 2s linear infinite !default;
$aui-loader-overlay-icon-path-animation: loading-dash 1.5s ease-in-out infinite !default;

@import './../css-variables/components/loader-overlay.scss';

// Styles
.aui-loader-overlay {
  position: relative;

  &__mask {
    width: 100%;
    height: 100%;
    max-height: 100dvh;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;

    background: var(--aui-loader-overlay-background);

    &-icon {
      display: inline-flex;
      align-items: center;

      @keyframes loading-rotate {
        transform: rotate(360deg);
      }

      @keyframes loading-dash {
        0% {
          stroke-dasharray: 1, 200;
          stroke-dashoffset: 0;
        }
        50% {
          stroke-dasharray: 90, 150;
          stroke-dashoffset: -40;
        }
        100% {
          stroke-dasharray: 90, 150;
          stroke-dashoffset: -120;
        }
      }

      max-height: var(--aui-loader-overlay-icon-max-size);
      height: 20%;

      svg, i {
        @include aui-transition-base;

        width: auto;
        height: 100%;

        color: var(--aui-loader-overlay-icon-color);

        animation: var(--aui-loader-overlay-icon-animation);

        path, circle {
          @include aui-transition-base;

          animation: var(--aui-loader-overlay-icon-path-animation);
        }
      }
    }

    &-content {
      font-size: var(--aui-loader-overlay-font-size);
      font-weight: var(--aui-loader-overlay-font-weight);

      margin-top: var(--aui-margin--md);

      * {
        color: var(--aui-loader-overlay-color);
      }
    }
  }
}

body.aui-loader-overlay {
  .aui-loader-overlay__mask {
    position: fixed;
  }
}