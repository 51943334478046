@import 'variables/utilities';

// Width
.aui-width {
	&-full {
		width: var(--aui-full);
	}

	&-half {
		width: var(--aui-half);
	}

	&-third {
		width: var(--aui-third);
	}

	&-two-thirds {
		width: var(--aui-two-thirds);
	}

	&-quarter {
		width: var(--aui-quarter);
	}
}

// Height
.aui-height {
	&-full {
		height: var(--aui-full);
	}

	&-half {
		height: var(--aui-half);
	}

	&-third {
		height: var(--aui-third);
	}

	&-two-thirds {
		height: var(--aui-two-thirds);
	}

	&-quarter {
		height: var(--aui-quarter);
	}
}

// Border radius
.aui-rounded {
	border-radius: var(--aui-border-radius--base);

	overflow: hidden;

	&-none {
		border-radius: var(--aui-border-radius--none);
	}

	&-sm {
		border-radius: var(--aui-border-radius--sm);
	}

	&-base {
		border-radius: var(--aui-border-radius--base);
	}

	&-md {
		border-radius: var(--aui-border-radius--md);
	}

	&-lg {
		border-radius: var(--aui-border-radius--lg);
	}

	&-full {
		border-radius: var(--aui-border-radius--full);
	}
}

// Box shadow
.aui-shadow {
	box-shadow: var(--aui-box-shadow--base);

	&-none {
		box-shadow: var(--aui-box-shadow--none);
	}

	&-sm {
		box-shadow: var(--aui-box-shadow--sm);
	}

	&-base {
		box-shadow: var(--aui-box-shadow--base);
	}

	&-md {
		box-shadow: var(--aui-box-shadow--md);
	}

	&-lg {
		box-shadow: var(--aui-box-shadow--lg);
	}
}


// Color
.aui-color {
	&-white {
		color: var(--aui-color-white);
	}

	&-black {
		color: var(--aui-color-black);
	}

	&-primary {
		color: var(--aui-color-primary);
	}

	&-secondary {
		color: var(--aui-color-secondary);
	}

	&-success {
		color: var(--aui-color-success);
	}

	&-danger {
		color: var(--aui-color-danger);
	}

	&-error {
		color: var(--aui-color-error);
	}

	&-warning {
		color: var(--aui-color-warning);
	}

	&-info {
		color: var(--aui-color-info);
	}

	&-dark {
		color: var(--aui-color-dark);
	}

	&-text {
		color: var(--aui-color-text);
	}
}

// Background
.aui-background {
	&-white {
		background: var(--aui-color-white);
	}

	&-black {
		background: var(--aui-color-black);
	}

	&-primary {
		background: var(--aui-color-primary);
	}

	&-secondary {
		background: var(--aui-color-secondary);
	}

	&-success {
		background: var(--aui-color-success);
	}

	&-danger {
		background: var(--aui-color-danger);
	}

	&-error {
		background: var(--aui-color-error);
	}

	&-warning {
		background: var(--aui-color-warning);
	}

	&-info {
		background: var(--aui-color-info);
	}

	&-dark {
		background: var(--aui-color-dark);
	}

	&-text {
		background: var(--aui-color-text);
	}
}

// Text
.aui-text {
	&-left {
		text-align: left;
	}

	&-center {
		text-align: center;
	}

	&-right {
		text-align: right;
	}

	&-justify {
		text-align: justify;
	}

	&-uppercase {
		text-transform: uppercase;
	}

	&-lowercase {
		text-transform: lowercase;
	}

	&-capitalize {
		text-transform: capitalize;
	}

	&-nowrap {
		white-space: nowrap;
	}

	&-break {
		word-break: break-word;
	}

	&-truncate {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&-regular {
		font-weight: var(--aui-font-weight--base);
	}

	&-semi-bold {
		font-weight: var(--aui-font-weight--semi-bold);
	}

	&-bold {
		font-weight: var(--aui-font-weight---bold);
	}
}

// Grid
.aui-grid {
	display: grid;
	gap: var(--aui-grid-gap--y) var(--aui-grid-gap--x);
	grid-template-columns: var(--aui-grid-col-max-width);

	&-col-2 {
		grid-template-columns: var(--aui-grid-col-max-width) var(--aui-grid-col-max-width);

		&:not(.aui-grid--no-responsive) {
			grid-template-columns: var(--aui-grid-col-max-width);

			@media #{$aui-breakpoint-xs} {
				grid-template-columns: var(--aui-grid-col-max-width) var(--aui-grid-col-max-width);
			}
		}
	}

	&-col-3 {
		grid-template-columns: var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width);

		&:not(.aui-grid--no-responsive) {
			grid-template-columns: var(--aui-grid-col-max-width);

			@media #{$aui-breakpoint-xs} {
				grid-template-columns: var(--aui-grid-col-max-width) var(--aui-grid-col-max-width);
			}

			@media #{$aui-breakpoint-sm} {
				grid-template-columns: var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width);
			}
		}
	}

	&-col-4 {
		grid-template-columns: var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width);

		&:not(.aui-grid--no-responsive) {
			grid-template-columns: var(--aui-grid-col-max-width);

			@media #{$aui-breakpoint-xs} {
				grid-template-columns: var(--aui-grid-col-max-width) var(--aui-grid-col-max-width);
			}

			@media #{$aui-breakpoint-sm} {
				grid-template-columns: var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width);
			}

			@media #{$aui-breakpoint-md} {
				grid-template-columns: var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width);
			}
		}
	}

	&-col-5 {
		grid-template-columns: var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width);

		&:not(.aui-grid--no-responsive) {
			grid-template-columns: var(--aui-grid-col-max-width);

			@media #{$aui-breakpoint-xs} {
				grid-template-columns: var(--aui-grid-col-max-width) var(--aui-grid-col-max-width);
			}

			@media #{$aui-breakpoint-sm} {
				grid-template-columns: var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width);
			}

			@media #{$aui-breakpoint-md} {
				grid-template-columns: var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width);
			}

			@media #{$aui-breakpoint-lg} {
				grid-template-columns: var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width);
			}
		}
	}

	&-col-6 {
		grid-template-columns: var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width);

		&:not(.aui-grid--no-responsive) {
			grid-template-columns: var(--aui-grid-col-max-width) var(--aui-grid-col-max-width);

			@media #{$aui-breakpoint-xs} {
				grid-template-columns: var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width);
			}

			@media #{$aui-breakpoint-sm} {
				grid-template-columns: var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width);
			}

			@media #{$aui-breakpoint-md} {
				grid-template-columns: var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width);
			}

			@media #{$aui-breakpoint-lg} {
				grid-template-columns: var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width);
			}
		}
	}

	&-col-7 {
		grid-template-columns: var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width);

		&:not(.aui-grid--no-responsive) {
			grid-template-columns: var(--aui-grid-col-max-width) var(--aui-grid-col-max-width);

			@media #{$aui-breakpoint-xs} {
				grid-template-columns: var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width);
			}

			@media #{$aui-breakpoint-sm} {
				grid-template-columns: var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width);
			}

			@media #{$aui-breakpoint-md} {
				grid-template-columns: var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width);
			}

			@media #{$aui-breakpoint-lg} {
				grid-template-columns: var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width) var(--aui-grid-col-max-width);
			}
		}
	}

	@for $i from 1 through 7 {
		&-col-span-#{$i} {
			@media #{$aui-breakpoint-xs} {
				grid-column: span #{$i};
			}
		}
	}
}

// Flex
.aui-flex {
	display: flex;

	&-row {
		flex-direction: row;
	}

	&-row-reverse {
		flex-direction: row-reverse;
	}

	&-col {
		flex-direction: column;
	}

	&-col-reverse {
		flex-direction: column-reverse;
	}

	&-wrap {
		flex-wrap: wrap;
	}

	&-wrap-reverse {
		flex-wrap: wrap-reverse;
	}

	&-nowrap {
		flex-wrap: nowrap;
	}

	&-justify-start {
		justify-content: flex-start;
	}

	&-justify-end {
		justify-content: flex-end;
	}

	&-justify-center {
		justify-content: center;
	}

	&-justify-between {
		justify-content: space-between;
	}

	&-align-start {
		align-items: flex-start;
	}

	&-align-end {
		align-items: flex-end;
	}

	&-align-center {
		align-items: center;
	}
}

// Spacing
.aui-gap {
	gap: var(--aui-gap--base);

	&-none {
		gap: var(--aui-gap--none);
	}

	&-xs {
		gap: var(--aui-gap--xs);
	}

	&-sm {
		gap: var(--aui-gap--sm);
	}

	&-base {
		gap: var(--aui-gap--base);
	}

	&-md {
		gap: var(--aui-gap--md);
	}

	&-lg {
		gap: var(--aui-gap--lg);
	}

	&-xl {
		gap: var(--aui-gap--xl);
	}
}

.aui-padding {
	padding: var(--aui-padding--base);

	&-none {
		padding: var(--aui-padding--none);
	}

	&-sm {
		padding: var(--aui-padding--xs);
	}

	&-sm {
		padding: var(--aui-padding--sm);
	}

	&-base {
		padding: var(--aui-padding--base);
	}

	&-md {
		padding: var(--aui-padding--md);
	}

	&-lg {
		padding: var(--aui-padding--lg);
	}

	&-xl {
		padding: var(--aui-padding--xl);
	}

	// X axis
	&-x-none {
		padding-left: var(--aui-padding--none);
		padding-right: var(--aui-padding--none);
	}

	&-x-xs {
		padding-left: var(--aui-padding--xs);
		padding-right: var(--aui-padding--xs);
	}

	&-x-sm {
		padding-left: var(--aui-padding--sm);
		padding-right: var(--aui-padding--sm);
	}

	&-x, &-x-base {
		padding-left: var(--aui-padding--base);
		padding-right: var(--aui-padding--base);
	}

	&-x-md {
		padding-left: var(--aui-padding--md);
		padding-right: var(--aui-padding--md);
	}

	&-x-lg {
		padding-left: var(--aui-padding--lg);
		padding-right: var(--aui-padding--lg);
	}

	&-x-xl {
		padding-left: var(--aui-padding--xl);
		padding-right: var(--aui-padding--xl);
	}

	// Y axis
	&-y-none {
		padding-top: var(--aui-padding--none);
		padding-bottom: var(--aui-padding--none);
	}

	&-y-xs {
		padding-top: var(--aui-padding--xs);
		padding-bottom: var(--aui-padding--xs);
	}

	&-y-sm {
		padding-top: var(--aui-padding--sm);
		padding-bottom: var(--aui-padding--sm);
	}

	&-y, &-y-base {
		padding-top: var(--aui-padding--base);
		padding-bottom: var(--aui-padding--base);
	}

	&-y-md {
		padding-top: var(--aui-padding--md);
		padding-bottom: var(--aui-padding--md);
	}

	&-y-lg {
		padding-top: var(--aui-padding--lg);
		padding-bottom: var(--aui-padding--lg);
	}

	&-y-xl {
		padding-top: var(--aui-padding--xl);
		padding-bottom: var(--aui-padding--xl);
	}

	// Top
	&-t-none {
		padding-top: var(--aui-padding--none);
	}

	&-t-xs {
		padding-top: var(--aui-padding--xs);
	}

	&-t-sm {
		padding-top: var(--aui-padding--sm);
	}

	&-t, &-t-base {
		padding-top: var(--aui-padding--base);
	}

	&-t-md {
		padding-top: var(--aui-padding--md);
	}

	&-t-lg {
		padding-top: var(--aui-padding--lg);
	}

	&-t-xl {
		padding-top: var(--aui-padding--xl);
	}

	// Right
	&-r-none {
		padding-right: var(--aui-padding--none);
	}

	&-r-xs {
		padding-right: var(--aui-padding--xs);
	}

	&-r-sm {
		padding-right: var(--aui-padding--sm);
	}

	&-r, &-r-base {
		padding-right: var(--aui-padding--base);
	}

	&-r-md {
		padding-right: var(--aui-padding--md);
	}

	&-r-lg {
		padding-right: var(--aui-padding--lg);
	}

	&-r-xl {
		padding-right: var(--aui-padding--xl);
	}

	// Bottom
	&-b-none {
		padding-bottom: var(--aui-padding--none);
	}

	&-b-xs {
		padding-bottom: var(--aui-padding--xs);
	}

	&-b-sm {
		padding-bottom: var(--aui-padding--sm);
	}

	&-b, &-b-base {
		padding-bottom: var(--aui-padding--base);
	}

	&-b-md {
		padding-bottom: var(--aui-padding--md);
	}

	&-b-lg {
		padding-bottom: var(--aui-padding--lg);
	}

	&-b-xl {
		padding-bottom: var(--aui-padding--xl);
	}

	// Left
	&-l-none {
		padding-left: var(--aui-padding--none);
	}

	&-l-xs {
		padding-left: var(--aui-padding--xs);
	}

	&-l-sm {
		padding-left: var(--aui-padding--sm);
	}

	&-l, &-l-base {
		padding-left: var(--aui-padding--base);
	}

	&-l-md {
		padding-left: var(--aui-padding--md);
	}

	&-l-lg {
		padding-left: var(--aui-padding--lg);
	}

	&-l-xl {
		padding-left: var(--aui-padding--xl);
	}
}

.aui-margin {
	margin: var(--aui-margin--base);

	&-none {
		margin: var(--aui-margin--none);
	}

	&-xs {
		margin: var(--aui-margin--xs);
	}

	&-sm {
		margin: var(--aui-margin--sm);
	}

	&-base {
		margin: var(--aui-margin--base);
	}

	&-md {
		margin: var(--aui-margin--md);
	}

	&-lg {
		margin: var(--aui-margin--lg);
	}

	&-xl {
		margin: var(--aui-margin--xl);
	}

	// X axis
	&-x-none {
		margin-left: var(--aui-margin--none);
		margin-right: var(--aui-margin--none);
	}

	&-x-xs {
		margin-left: var(--aui-margin--xs);
		margin-right: var(--aui-margin--xs);
	}

	&-x-sm {
		margin-left: var(--aui-margin--sm);
		margin-right: var(--aui-margin--sm);
	}

	&-x, &-x-base {
		margin-left: var(--aui-margin--base);
		margin-right: var(--aui-margin--base);
	}

	&-x-md {
		margin-left: var(--aui-margin--md);
		margin-right: var(--aui-margin--md);
	}

	&-x-lg {
		margin-left: var(--aui-margin--lg);
		margin-right: var(--aui-margin--lg);
	}

	&-x-xl {
		margin-left: var(--aui-margin--xl);
		margin-right: var(--aui-margin--xl);
	}

	// Y axis
	&-y-none {
		margin-top: var(--aui-margin--none);
		margin-bottom: var(--aui-margin--none);
	}

	&-y-xs {
		margin-top: var(--aui-margin--xs);
		margin-bottom: var(--aui-margin--xs);
	}

	&-y-sm {
		margin-top: var(--aui-margin--sm);
		margin-bottom: var(--aui-margin--sm);
	}

	&-y, &-y-base {
		margin-top: var(--aui-margin--base);
		margin-bottom: var(--aui-margin--base);
	}

	&-y-md {
		margin-top: var(--aui-margin--md);
		margin-bottom: var(--aui-margin--md);
	}

	&-y-lg {
		margin-top: var(--aui-margin--lg);
		margin-bottom: var(--aui-margin--lg);
	}

	&-y-xl {
		margin-top: var(--aui-margin--xl);
		margin-bottom: var(--aui-margin--xl);
	}

	// Top
	&-t-none {
		margin-top: var(--aui-margin--none);
	}

	&-t-xs {
		margin-top: var(--aui-margin--xs);
	}

	&-t-sm {
		margin-top: var(--aui-margin--sm);
	}

	&-t, &-t-base {
		margin-top: var(--aui-margin--base);
	}

	&-t-md {
		margin-top: var(--aui-margin--md);
	}

	&-t-lg {
		margin-top: var(--aui-margin--lg);
	}

	&-t-xl {
		margin-top: var(--aui-margin--xl);
	}

	// Right
	&-r-none {
		margin-right: var(--aui-margin--none);
	}

	&-r-xs {
		margin-right: var(--aui-margin--xs);
	}

	&-r-sm {
		margin-right: var(--aui-margin--sm);
	}

	&-r, &-r-base {
		margin-right: var(--aui-margin--base);
	}

	&-r-md {
		margin-right: var(--aui-margin--md);
	}

	&-r-lg {
		margin-right: var(--aui-margin--lg);
	}

	&-r-xl {
		margin-right: var(--aui-margin--xl);
	}

	// Bottom
	&-b-none {
		margin-bottom: var(--aui-margin--none);
	}

	&-b-xs {
		margin-bottom: var(--aui-margin--xs);
	}

	&-b-sm {
		margin-bottom: var(--aui-margin--sm);
	}

	&-b, &-b-base {
		margin-bottom: var(--aui-margin--base);
	}

	&-b-md {
		margin-bottom: var(--aui-margin--md);
	}

	&-b-lg {
		margin-bottom: var(--aui-margin--lg);
	}

	&-b-xl {
		margin-bottom: var(--aui-margin--xl);
	}

	// Left
	&-l-none {
		margin-left: var(--aui-margin--none);
	}

	&-l-xs {
		margin-left: var(--aui-margin--xs);
	}

	&-l-sm {
		margin-left: var(--aui-margin--sm);
	}

	&-l, &-l-base {
		margin-left: var(--aui-margin--base);
	}

	&-l-md {
		margin-left: var(--aui-margin--md);
	}

	&-l-lg {
		margin-left: var(--aui-margin--lg);
	}

	&-l-xl {
		margin-left: var(--aui-margin--xl);
	}
}

.aui-font-family {
	&-primary {
		font-family: var(--aui-font-family--primary);
	}

	&-secondary {
		font-family: var(--aui-font-family--secondary);
	}
}

.aui-sr-only {
	display: none;
	visibility: hidden;
	opacity: 0;
	height: 0;
	width: 0;
}

.aui-stretched-click-zone {
	&::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		content: '';
	}
}