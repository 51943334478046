.widget {
    margin: 20px 0;

    &__gallery {
        display: flex;
    }

    &-products {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    &-contact_form {

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .form-group {
            width: 100%;
            padding: 5px;
            display: flex;
            flex-wrap: wrap;

            &.w50 {
                width: 50%;
            }

            label {
                width: 100%;
                margin-bottom: 5px;
            }

            input {
                width: 100%;
                height: 40px;
                line-height: 40px;
                padding: 0 10px;
            }

            textarea {
                width: 100%;
            }
        }
    }

    &__call-to-action {
        padding: 20px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        h3 {
            font-size: 3rem;
            line-height: 4rem;
            margin-bottom: 5px;
        }

        &__subtitle {
            font-size: 2rem;
        }
    }
}