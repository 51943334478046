.header-nav-toggler {
	display: none;
}

.header-nav {
  display: block;
  background-color: #407B32;
  @include radius(5px);
  @include font-size(0);

  flex-basis: 80%;

  @include query($grid-l) {
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 10003;
	@include radius(0);
  }

  a {
	&:hover {
	  text-decoration: none;
	}
  }

  .main-menu {
	display: flex;
	flex-direction: row;
	justify-content: center;
	  @include reset-ul();

	@include query($grid-l) {
		flex-direction: column;
		height: calc(100vh);
		align-items: center;
	}

	  .only-mobile {
		  display: none;
	  }
  }

  &__base {
	display: inline-block;
	flex: 1;
	border-left: 2px solid #67A05A;

	@include query($grid-l) {
	  border: 0;
	  width: 100%;
		flex: initial;
	  text-align: center;
	}

	&:before {
	  position: absolute;
	  left: 0;
	  background-color: #67A05A;
	  width: 2px;
	  height: 100%;
	  top: 0;
	}

	&:first-of-type {
	  border-left: 0;

	  a {
		@include radius(5px 0 0 5px);
	  }
	}

	&:last-of-type a {
	  @include radius(0 5px 5px 0);
	}

	& > a {
	  @include font-size(22px);
	   font-weight: 500;
	  position: relative;
	  display: block;
	  text-align: center;
	  color: white;
	  line-height: 62px;

	  @include query(700px) {
		line-height: 50px;
	  }

	  &:hover {
		background-color: #306424;
	  }
	}
  }

  &__multi {

	& > a:after {
	  position: relative;
	  display: inline-block;
	  vertical-align: middle;
	  margin-left: 8px;
	  content: "";
	  @include sprite(nav-arrow);

	  @include query($grid-l) {

	  }
	}

	&__show {
	  position: absolute;
	  display: none;
	  left: 0;
	  width: 100%;
	  background-color: white;
	  border-top: 1px solid #D8D8D8;
	  border-bottom: 1px solid #D8D8D8;
	  padding: 30px 0;
	  z-index: 10003;
	  flex-wrap: wrap;
	  justify-content: space-between;
	  @include font-size(16px);

		.header--pinned & {
			border-top: 0;
		}

	  .wide {
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: stretch;
	  }

	  .restofthem {
		  flex: 3;
		  display: flex;
		  flex-direction: row;
	  }

	  	&-item {
			display: flex;
			flex-direction: column;
			padding: 0 3%;
			flex: 1;

			h3 {
				line-height: normal;
				 font-weight: 500;
				@include font-size(20px);
				margin-bottom: 0;
			}

			ul {
				@include reset-ul();

				li {
					margin: 7px 0;

					a {	
						&:hover {
							text-decoration: underline;
							@include animate(400);
						}
					}
				}
				
				&.with-icons {
					li {
						&::before {
							content: "";
							display: inline-block;
							width: 15px;
							height: 15px;
							border: 1px solid transparent;
							background: white;
							margin-right: 4px;
							position: relative;
							top: 2px;
							border-radius: $radius;
						}

						&.white::before{
							background-color: #fff;
							border-color: #c0c0c0;
						}
						
						&.antracit::before{
							background-color: #575c61;
						}

						&.black::before{
							background-color: #000;
						}

						&.grey::before{
							background-color: #cccccc;
						}

						&.brown::before{
							background-color: #7c5608;
						}

						&.red::before{
							background-color: $cl-red;
						}

						&.natural::before{
							background-color: #f7d6a0;
						}
						
					}

					li.back {
						&::before {
							display: none;
						}
					}
				}
			}	
		}

		.main {
			
			@include font-size(20px);
			flex: 1.5;
			margin-left: 5%;

			@include query($grid-xl) {
				flex: 1;
			}

			ul {
				@include reset-ul();
				display: flex;
				flex-direction: column;
				height: 100%;
				border-right: 3px solid $cl-light;
				justify-content: center;

				li {
					margin: 10px 0;
				}

				li:last-child {

					a {
						text-decoration: underline;
						color: $cl-cta;

						&:hover {
							color: darken($cl-cta,30%);
						}
					}
					
				}
			}			
		}
	}

	&:hover {

		.header-nav__multi__show {
			display: block;
		}


		& > a:before {
			position: absolute;
			bottom: 0;
			content: "";
			@include sprite(sub-arrwo, ha);
			@include query($width-layout) {
				display: none;
			}
		}
	}
  }
}

@include query($grid-l) {
	.header-nav-toggler {
		padding: 7px;
		border: none;
		background: none;
		order: 2;
		height: 50px;
		display: flex;
		flex-direction: column;
		align-items: center;
		@include font-size(10px);
		cursor: pointer;

		svg {
			height: 32px;
			width: 32px;
		}
	}

	.header-nav {
		top: 70px;
		display: none;

		&.open {
			display: block;
		}
	}

	.header-nav__multi__show {
		display: block;
		border: none;
		background: none;
		pointer-events: none;
		top: 0;

		padding: 0;
		margin: 0;

		height: calc(100vh - 70px);

		.wide {
			display: block;
			height: 100%;
		}
	}

	.header-nav__multi__show-item {
		position: absolute;
		left: 100%;
		width: 100%;
		height: 100%;
		padding: 10%;
		margin: 0;
		background: #fff;
		pointer-events: all;
		transition: left 0.5s;
		display: flex;
		align-content: center;
		justify-content: center;
		

		&.main {
			margin: 0;

			ul {
				border: 0;
			}
		}

		&.open {
			left: 0;
			-webkit-transition: all 0.5s ease;
			-moz-transition: all 0.5s ease;
			-o-transition: all 0.5s ease;
			transition: all 0.5s ease;
		}

		ul li {
			text-align: left;
			@include font-size(20px);
		}

		h3 {
			text-align: left;
			@include font-size(24px);
			margin-bottom: 20px;
		}

		.back {
			margin-bottom: 30px;
			a {
				&:before {
					content:"\2190";
					display: inline-block;
					width: 15px;
					height: 15px;
					border: 0;
					background:transparent;
					margin-right: 10px;
					position: relative;
					top: -4px;
				}
			}
		}

	}


	.main-menu {
		.only-mobile {
			display: list-item !important;
		}
	}
}

