#cc--main {
  --cc-bg: #fff;
  --cc-text: #000;
  --cc-btn-primary-bg: #407B32;
  --cc-btn-primary-text: #fff;
  --cc-btn-primary-hover-bg: #306424;
  --cc-btn-secondary-bg: #fff;
  --cc-btn-secondary-text: var(--cc-text);
  --cc-btn-secondary-hover-bg: #fff;
  --cc-toggle-bg-off: #c0c0c0;
  --cc-toggle-bg-on: var(--cc-btn-primary-bg);
  --cc-toggle-bg-readonly: #c5c5c5;
  --cc-toggle-knob-bg: #fff;
  --cc-toggle-knob-icon-color: #ecf2fa;
  --cc-block-text: var(--cc-text);
  --cc-cookie-category-block-bg: #f0f4f7;
  --cc-cookie-category-block-bg-hover: #e9eff4;
  --cc-section-border: #f1f3f5;
  --cc-cookie-table-border: #e9edf2;
  --cc-overlay-bg: rgba(189, 172, 142, 0.7);
  --cc-webkit-scrollbar-bg: #cfd5db;
  --cc-webkit-scrollbar-bg-hover: #9199a0;
}


#cc_div * {
  font-size: 1rem;
  line-height: 1.5;
}

#cc_div .c-bn {
  font-family: "Open Sans", 'Open Sans-fallback', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  text-decoration: underline;
  border-radius: 5px;
  padding: 1rem 1.7rem;

  &:first-child {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }
}

#cc_div #cm {
  box-shadow: 0 7px 36px 0 #020202 !important;
  border-radius: 0 !important;
}

#cc_div #c-ttl {
  font-family: "Open Sans", 'Open Sans-fallback', sans-serif;
  font-weight: 500;
  font-size: 1.6rem;

  &::before {
    content: url('https://api.iconify.design/mdi/cookie-outline.svg?width=27');
    width: auto;
    height: 100%;
    font-size: 27px;
    margin-right: 7px;
    vertical-align: middle;
  }
}

#cc_div #c-txt {
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 2.2rem;
}

#cc_div #cm, #cc_div #s-inr {
  border-radius: 5px;
  font-size: 1.4rem;
}

#cc_div #cm.cloud {
  padding: 2.3em 2em;
  font-family: "Open Sans", 'Open Sans-fallback', sans-serif;
}

#s-ttl {
  font-size: 2rem;
  font-family: "Open Sans", 'Open Sans-fallback', sans-serif;
  font-weight: 300;
}

#s-bns {
  height: auto !important;
}

#c-inr {
  max-width: 59em !important;
}