.button {
	width: auto;
	padding: 8px 28px;
	line-height: 1.5;
	display: inline-flex;
	align-items: center;
	text-decoration: none !important;
	user-select: none;

	gap: 8px;

	border: $btn-border-width solid $btn-border;
	color: $btn-color;
	background: $btn-bg;
	cursor: pointer;
	outline: none;

	@include font-size($font-size-small);
	@include radius($radius);
	@include animate();
	 font-weight: 700;

	&[disabled] {
		opacity: .4;
		cursor: not-allowed;
	}

	&:hover {
		border-color: $btn-border-hover;
		color: $btn-color;
		background: $btn-bg-hover;
	}

	&-primary {
		background-color: $cl-cta;
		color: $cl-white;
		border-color: $cl-cta;

		&:hover {
			background-color: darken($color: $cl-cta, $amount: 10%);
			color: $cl-white !important;
			border-color: $cl-cta;
		}
	}

	&-secondary {
		background-color: $cl-cta2;
		color: $cl-white;
		border-color: $cl-cta2;

		&:hover {
			background-color: darken($color: $cl-cta2, $amount: 10%);
			color: $cl-white !important;
			border-color: $cl-cta2;
		}
	}
}

.button-small {
	padding: 4px 18px;
	@include font-size(13px);
}

.button--large {
	padding: 16px 28px;
}

.button--block {
	width: 100%;
	justify-content: center;
}

.button--textual {
	border: none;
	background: transparent;
}