.product-gallery-block {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @include query-min(1280px) {
    flex-direction: row;
  }

  &__carousel {
    display: block;
    width: 100%;
    margin: 0 1.25rem 0 0;
    padding: 0 0 0 0;
    position: relative;



    //
    //@include query(1024px) {
    //  display: none;
    //}

    &__wrap {

    }

    &__link {
      display: block;
      margin: 0 5px;

      width: 100%;

      text-align: center;
      cursor: pointer;

      img {
        max-width: 100%;
        height: 100%;
        border-radius: $radius;
      }
    }

    &__arrow {
      position: absolute;
      display: flex;
      top: 50%;
      transform: translateY(-50%);

      align-items: center;

      height: 80px;
      z-index: 10001;

      padding: 0 6px;

      background-color: $cl-white;
      border: 1px solid $cl-grey;
      color: $cl-green;

      user-select: none;
      cursor: pointer;

      border-radius: $radius;

      text-align: center;
       font-weight: 700;
      @include font-size(24px);

      box-shadow: rgba(0, 0, 0, 0.15) 0 3px 8px;

      @include query(1280px) {
        height: 45px;
      }

      &.is-previous {
        left: 15px;
      }

      &.is-next {
        right: 15px;
      }
    }
  }

  &__images {
    backface-visibility: hidden;
    display: block;
    flex: 1;
    max-width: 100%;
    margin-bottom: $margin-gap;
    background: #f3f3f3;
    border-radius: $radius;

    margin-left: 0 !important;
    margin-right: 1.25rem !important;

    &__wrap {
      //height: 570px;
      //
      //@include query(1280px) {
      //  height: 470px;
      //}
      //
      //@include query(800px) {
      //  height: 400px;
      //}
      //
      //@include query(400px) {
      //  height: 300px;
      //}
    }

    &__link {
      display: flex;
      justify-content: center;
      height: 100%;
      width: 100%;
      position: relative;
      text-align: center;
      cursor: pointer;
      align-self: center;
      align-items: center;

      img {
        display: inline-block;
        vertical-align: middle;
        max-width: 100%;
        height: auto;

        text-align: center;
        border-radius: $radius;
      }
    }

    &__open-gallery {
      display: none;
    }
  }

  &__pagination {
    position: relative;

    display: none;

    height: 50px;
    padding: 25px;

    text-align: center;

    @include query(1200px) {
      display: none;
    }

    .swiper-pagination-bullet {
      display: inline-block;

      width: 11px;
      height: 11px;

      margin: 0 5px !important;

      background-color: $cl-cta;
      border-radius: 11px;

      transition: .5s all ease;

      cursor: pointer;
    }

    .swiper-pagination-bullet-active {
      background-color: $cl-green;
    }
  }

}
