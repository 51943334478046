body {
  color: #4e4e4e;
  //font-size: $font-size;
  font-family: $font-base;
    line-height: 1.5;
    margin: 0;
    font-weight: 400;
}

strong {
    font-weight: 700;
}

a {
	color: $cl-dark;
	@include animate;
	cursor: pointer;
	outline: none;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  text-rendering: optimizelegibility;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  display: block;
}

h1,
.h1 {
  @include font-size(36px);
  @include light;
  color: #222;
  display: block;
  position: relative;
  text-align: center;

  span {
	@include semi;
  }
}

h1 {
  text-transform: uppercase;
}

h2,
.h2 {
    @include font-size(28px);
    color: #407b32;
    display: block;
    position: relative;
}

h3,
.h3 {
  @include font-size(24px);
  color: #407b32;
  display: block;
  position: relative;
}

ul li {
    &::marker {
        color: $cl-cta;
    }
}