body {

  overflow-x: hidden;
  @include query-min($width-layout) {
    &.fix {
      margin-top: 200px;
    }
  }

  &.noscroll {
    overflow: hidden;
  }
}

.wrap {
  margin: 0 auto;
  width: $width-layout;

  @include query($width-layout) {
    width: auto;
    padding: 0 20px;
  }

  &--md {
    width: 100%;
    padding: 0 20px;
    max-width: 768px;
  }

  &--l {
    width: 100%;
    padding: 0 20px;
    max-width: 1170px;
  }

  &--xl {
    width: 100%;
    padding: 0 20px;
    max-width: 1280px;
  }

  &--xxl {
    width: 100%;
    padding: 0 20px;
    max-width: 1580px;
  }
}

.wide {
  width: 100%;
  padding: 0 20px;
  max-width: 1800px;
}

.container-fluid {
  max-width: 1800px;
}

.row {
  margin: 0 -14px;

  [class*=grid] {
    padding: 0 14px;
  }
}

blockquote {
  &:before {
    content: "\2018\2018";
  }

  &::after {
    content: "\2019\2019";
  }
}

.clearfix,
.row {
  @include clearfix;
}

.skrejvadlo {
  display: none;
  position: relative;
  text-align: center;
  @include sprite(nav);
  @include animate(400);


  &.active {
    @include rotate(180);
  }


  @include query($width-layout) {
    display: block;
  }
}

.skryj {
  @include query($width-layout) {
    display: none;
  }

  @include query-min($width-layout) {
    display: block !important;
  }
}

hr {
  height: 1px;
  border: 1px solid #e0e0e0;
  margin: 40px 0;
}

#filterAjaxLoader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  overflow: visible;
  background: rgba(51, 51, 51, 0.4) url('../../../public/frontend/images/infinityMaxi.gif') no-repeat center center;
}

.box-article img, p img {
  max-width: 100% !important;
}

p.info {
  @include font-size(12px);
  border: 1px solid #ecd563;
  padding: 10px;
  text-align: center;
}
