footer {
  padding: 30px 20px;
  background-color: #636659;
  line-height: 1.5;

  .wrap {
    display: flex;
    color: $cl-white;
    align-items: stretch;

    a {
      color: $cl-white;
    }
  }

  .contact {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      @include font-size(20px);
      margin-bottom: 20px;
    }

    a {
      @include font-size(20px);
       font-weight: 700;
      text-decoration: none;
      display: block;
      margin-bottom: 20px;
      @include animate(400);

      &:hover {
        text-decoration: underline;
      }
    }

    .social {
      display: flex;
      padding-top: 40px;

      a {
        margin-right: 10px;
        margin-bottom: 0;
        background: #4a4e3c;
        @include font-size(18px);
        border-radius: .5rem;
        width: 33px;
        height: 33px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          text-decoration: none;
          background: #7B7F62;
        }
      }
    }
  }

  h4 {
    @include font-size(20px);
     font-weight: 700;
    margin-bottom: 20px;
  }

  .footer-menu {
    flex: 2;
    padding: 20px 0 0 20px;

    ul {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 200px;
      padding: 0 20px;
      column-count: 2;

      li {
        list-style-type: none;
        list-style-position: inside;
        position: relative;
        margin-left: 20px;
        margin-bottom: 7px;

        &:after {
          display: block;
          content: "";
          width: 10px;
          height: 1px;
          position: absolute;
          top: 50%;
          left: -20px;
          background: $cl-white;
        }
      }
    }     
  }

  .newsletter {
    flex: 1;
    padding: 20px;

    .gdpr {
      @include font-size(12px);
    }

    form {
      display: flex;
      margin-top: 30px;

      input {
        height: 50px;
        line-height: 50px;
        width: 70%;
        padding-left: 10px;
        border: 0;
        border-top-left-radius: $radius;
        border-bottom-left-radius: $radius;
      }

      input.btn {
        width: 30%;
        background: darken($cl-light,20%);
        text-align: center;
        height: 50px;
        padding-left: 0;

      }

      button {
        border: 0;
        text-align: center;
        padding: 0 10px;
        @include font-size(12px);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
  @include query($grid-l) {

    .wrap {
      flex-direction: row;
      flex-wrap: wrap;
    }
    

    .contact {
      flex-basis: 50%;
  
      p {
        @include font-size(24px);
        line-height: 32px;
        margin-bottom: 20px;
        margin-top: 0;
      }
  
      .social {
        padding-top: 10px;
      }
    }

    .footer-menu {
      flex: 1;
      padding-top: 0;
      flex-basis: 50%;
  
      ul {
        flex-wrap: nowrap;
        height: 300px;
  
        li {
          width: 100%;
        }
      }     
    }

    .newsletter {
      padding-top: 30px;
    }
  }

  @include query($grid-m) {
    .contact {
      flex-basis: 100%;
      padding-bottom: 40px;
    }

    .footer-menu {
      flex-basis: 100%;
      padding-bottom: 40px;
      padding-left: 0;
      padding-right: 0;

      ul {
        height: auto;
      }
    }

    .newsletter {
      flex-basis: 100%;
      padding: 0;
    }
  }
}

.f7 {
  background-color: #4a4e3c;
  display: block;
  padding: 20px 0;
  @include font-size(14px);
  text-align: center;

  a {
    color: #9ea097;
  }
}

